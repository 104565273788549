import React, { useCallback, useEffect, useState } from 'react';
import { useInstances } from 'react-ioc';
import { AgencyAdminProfileDefinition } from '@assets/models/security/agencyAdmins.model';
import { AgenciesStore } from '../../../Stores/Agencies.store';
import { getListOfProfiles } from '../../../assets/utils/security/agencyAdmins.util';

import { DataSpreadsheet } from 'ui/DataSpreadsheet';
import { ProfileColumns, ProfileRows } from './ProfileDataSpreadSheet';
import { toJS } from 'mobx';

type LocalStore = [AgenciesStore];

const ProfilesList = () => {
  const [agenciesStore]: LocalStore = useInstances<LocalStore>(AgenciesStore);
  const { currentAgency } = agenciesStore;
  const [listOfProfiles, setListOfProfiles] = useState<AgencyAdminProfileDefinition[]>([]);

  const fetchProfiles: () => Promise<void> = useCallback(async () => {
    if (currentAgency?.uid) {
      const profiles = await getListOfProfiles(currentAgency.uid);
      setListOfProfiles(profiles);
    }
  }, [currentAgency?.uid]);

  useEffect(() => {
    fetchProfiles();
  }, [fetchProfiles]);

  return (
    <DataSpreadsheet
      columns={ProfileColumns}
      rows={ProfileRows(toJS(listOfProfiles))}
    />
  );
};

export default ProfilesList;
