import { observer } from 'mobx-react-lite';
import React, { FunctionComponent, ReactElement, ReactNode } from 'react';
import { useInstance } from 'react-ioc';
import { Navigate, Outlet, Route, Routes, useNavigate } from 'react-router-dom';
import LoginPasswordPage from './Auth/LoginPassword.page';
import LoginResetPasswordPage from './Auth/LoginResetPassword.page';
import LoginPage from './Auth/Login.page';
import CGVsComponent from './Confidentiality/CGVs/CGVsComponent';
import LegalNoticeComponent from './Confidentiality/LegalNoticeComponent';
import MangoPayRGPD from './Confidentiality/MangoPayRGPD';
import RGPDComponent from './Confidentiality/RGPD/RGPDComponent';
import Layout from './Layout/Layout';
import DashboardPage from './Dashboard/Dashboard.page';
import PaymentRequestComponent from './PaymentRequest/PaymentRequest/PaymentRequestComponent';
import RechargingPage from './Recharging/Recharging.page';
import { UserConnectStore } from './Stores/UserConnect.store';
import CookiesComponent from './Cookies/CookiesComponent';
import { ContractType } from './assets/models/agencies/Agency.model';
import { AgenciesStore } from './Stores/Agencies.store';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import VouchersComponent from './Vouchers/VouchersComponent';
import VouchersInvoicesComponent from './Accounting/VouchersInvoicesComponent';
import SettingsPage from './Settings/SettingsPage';
import ProfilsParametersComponent from './Settings/Profiles/Profiles.page';
import AgencyAdminsPage from './Settings/AgencyAdmins/AgencyAdmins.page';
import AdminRegisterPage from './Auth/AdminRegister.page';
import NewRechargingProcessPage from './Recharging/NewRechargingProcess.page';
import AgencyPage from 'Agency/Agency.page';
import BeneficiariesPage from 'Beneficiaries/Beneficiaries.page';
import AddorUpdateAgencyAdminPage from 'Settings/AgencyAdmins/AddorUpdateAgencyAdmin.page';
import AccountingPage from './Accounting/Accounting.page';
import { Spinner } from './ui/Spinner';
import { PageBlock } from './ui/layout/PageBlock';
import { Button } from './ui/Buttons/Button';

interface IPrivateRouteProps {
  children: ReactNode;
  redirectTo?: string;
}

interface IContractTypeGuardedRouteProps extends IPrivateRouteProps {
  requiredContractTypes: ContractType[];
}

const PrivateRoute: React.FC<IPrivateRouteProps> = ({ children, redirectTo }): ReactElement | null => {
  const userConnectStore: UserConnectStore = useInstance<UserConnectStore>(UserConnectStore);

  return userConnectStore.isConnected
    ? <>{children}</>
    : <Navigate to={redirectTo ?? '/login'}/>;
};

export const ObservedPrivateRoute = observer(PrivateRoute);
export { ObservedPrivateRoute as PrivateRoute };

const ContractTypeGuardedRoute: React.FC<IContractTypeGuardedRouteProps> = ({
                                                                              children, redirectTo,
                                                                              requiredContractTypes,
                                                                            }, deprecatedLegacyContext): ReactElement | null => {
  const agenciesStore: AgenciesStore = useInstance<AgenciesStore>(AgenciesStore);
  const navigate = useNavigate();
  const { t } = useTranslation('common');

  if (!agenciesStore.currentAgency) {
    return <PageBlock><Spinner size={'lg'}/></PageBlock>;
  }

  return requiredContractTypes.includes(agenciesStore.currentAgency.contractType)
    ? <>{children}</>
    : (
      <PageBlock>
        <Typography  style={{ marginBottom: '2em' }}>
          {t('pageAccessDenied')}
        </Typography>

        <Button onClick={() => navigate('/dashboard')}>
          {t('pageRedirectToHome')}
        </Button>
      </PageBlock>
    )
    ;
};

export const ObservedContractTypeGuardedRoute = observer(ContractTypeGuardedRoute);
export { ObservedContractTypeGuardedRoute as ContractTypeGuardedRoute };

const AppModule: FunctionComponent = () => {

  return (
    <Routes>
      <Route path="/reload" element={null}/>

      <Route path="/login" element={<LoginPage/>}/>
      <Route path="/login/password" element={<LoginPasswordPage/>}/>
      <Route path="/login/reinitialisation" element={<LoginResetPasswordPage/>}/>

      <Route path="/reset-password" element={<AdminRegisterPage/>}/>
      <Route path="/register-admin" element={<AdminRegisterPage/>}/>

      <Route path="/cookies" element={<CookiesComponent/>}/>
      <Route path="/legalnotice" element={<LegalNoticeComponent/>}/>
      <Route path="/rgpd" element={<RGPDComponent/>}/>
      <Route path="/cgv" element={<CGVsComponent/>}/>
      <Route path="/mangopayrgpd" element={<MangoPayRGPD/>}/>

      <Route element={<PrivateRoute><Layout><Outlet/></Layout></PrivateRoute>}>
        <Route path="/dashboard" element={<DashboardPage/>}/>
        <Route path="/beneficiaries" element={<BeneficiariesPage/>}/>
        <Route path="/agency/*" element={<AgencyPage/>}/>
        <Route path="/agency" element={<Navigate to="/agency/information" replace/>}/>
        <Route path="/paymentrequest" element={<PaymentRequestComponent/>}/>

        <Route path="/settings" element={<SettingsPage/>}/>
        <Route path="/settings/agencyadmins" element={<AgencyAdminsPage/>}/>
        <Route path="/settings/profiles" element={<ProfilsParametersComponent/>}/>
        <Route path="/settings/add-agencyadmin" element={<AddorUpdateAgencyAdminPage/>}/>

        <Route element={<ContractTypeGuardedRoute
          requiredContractTypes={['STANDARD', 'GEOGRAPHIC_RESTRICTION']}><Outlet/></ContractTypeGuardedRoute>}>
          <Route path="/reloading" element={<RechargingPage/>}/>
          <Route path="/addnewrecharging" element={<NewRechargingProcessPage/>}/>
          <Route path="/continueOrder" element={<NewRechargingProcessPage/>}/>

          <Route path="/accounting/*" element={<AccountingPage/>}/>
          <Route path="/accounting" element={<Navigate to="/accounting/invoices" replace/>}/>
        </Route>

        <Route element={<ContractTypeGuardedRoute
          requiredContractTypes={['PUBLIC_MEAL_SUBSIDY']}><Outlet/></ContractTypeGuardedRoute>}>
          <Route path="/vouchers-transactions" element={<VouchersComponent/>}/>
          <Route path="/vouchers-invoices" element={<VouchersInvoicesComponent/>}/>
        </Route>
      </Route>

      { /*Redirect to a "default" route if no other routes match*/}
      <Route path="*" element={<Navigate to="/dashboard" replace/>}/>
    </Routes>
  );
};

export default AppModule;
