import { BusinessVouchersInvoiceModel } from '../assets/models/accountingDocuments/BusinessAccountingDocument.model';
import { FRENCH_MONTHS } from '../assets/utils/dates/dateTime.util';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { provider, useInstance } from 'react-ioc';
import { AgenciesStore } from '../Stores/Agencies.store';
import { observer } from 'mobx-react-lite';
import { useDebounce } from '../Hooks/useDebounce';
import { useTranslation } from 'react-i18next';
import { ContractedBusinessModel } from '../../../../lambdas/shared/models/discreteVouchers/ContractedBusiness.model';
import { getAllDocs, getDocsWhere } from '../Services/firebase.service';
import { getTimeStampMinusMonth } from '../assets/utils/dates/getParsedDate.util';
import { Timestamp } from 'firebase/firestore';
import { MenuItem, SelectChangeEvent } from '@mui/material';

import { ReactComponent as GlassesSvg } from '../assets/svg/glasses.svg';

import { PageBlock } from '../ui/layout/PageBlock';
import { SectionWrapper } from '../ui/layout/SectionWrapper';
import { Select } from '../ui/Select';
import { InputField } from '../ui/Input';
import VoucherInvoiceCard from '../Component/Cards/VoucherInvoiceCard';
import { getDownloadUrl } from '../Services/storage.service';
import { Dialog } from '../ui/Dialog';

const VouchersInvoicesComponent: FunctionComponent = provider()(observer(() => {
  const agenciesStore: AgenciesStore = useInstance<AgenciesStore>(AgenciesStore);
  const [invoices, setInvoices] = useState([]);
  const [search, setSearch] = useState('');
  const [sinceDate, setSinceDate] = useState<number>(6);
  const [debouncedSearch] = useDebounce(search, 500);
  const { t } = useTranslation(['accounting', 'voucherAccounting']);

  useEffect(() => {
    const getInvoices = async (agencyUid: string): Promise<void> => {
      const contractedBusinesses: ContractedBusinessModel[] = await getAllDocs<ContractedBusinessModel>(`AGENCIES/${agencyUid}/CONTRACTED_BUSINESSES`) ?? [];
      const fetchedInvoices: BusinessVouchersInvoiceModel[] = (await Promise.all(contractedBusinesses.map(async (business) =>
        getDocsWhere<BusinessVouchersInvoiceModel>(`BUSINESSES/${business.uid}/ACCOUNTING_DOCUMENTS`, [
          {
            fieldName: 'agencyUid',
            operator: '==',
            value: agencyUid,
          },
          {
            fieldName: 'documentType',
            operator: '==',
            value: 'Invoice:FOOD_VOUCHER',
          }])))).flat();

      setInvoices(fetchedInvoices);
    };

    const agencyUid: string | undefined = agenciesStore?.currentAgency?.uid;
    if (!!agencyUid) {
      getInvoices(agencyUid);
    }
  }, [agenciesStore.currentAgency]);

  const normalizeString = (str: string) =>
    str.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();

  const filteredInvoices: BusinessVouchersInvoiceModel[] = useMemo(() => {
    const normalizedSearch = normalizeString(debouncedSearch);

    return invoices
      .filter(i =>
        (i.invoiceDate as Timestamp).toMillis() >= getTimeStampMinusMonth(+sinceDate))
      .filter(
        ({ invoiceReference, businessCompanyName, totalAgencyParticipationAmount, invoiceDate, month }) => {
          const fieldsToSearch = [
            invoiceReference,
            businessCompanyName,
            String(totalAgencyParticipationAmount / 100),
            (invoiceDate as Timestamp).toDate().toLocaleDateString('fr-FR'),
            normalizeString(FRENCH_MONTHS[month - 1]),
          ];

          return fieldsToSearch.some(field => normalizeString(field).includes(normalizedSearch));
        },
      )
      .sort((a, b) => {
        const dateDifference = (b.invoiceDate as Timestamp).toMillis() - (a.invoiceDate as Timestamp).toMillis();
        return dateDifference !== 0 ? dateDifference : a.businessCompanyName.localeCompare(b.businessCompanyName);
      });
  }, [invoices, debouncedSearch, sinceDate]);

  interface Option {
    label: string;
    value: number;
  }

  const options: Option[] = [
    { label: t('since6months'), value: 6 },
    { label: t('since1year'), value: 12 },
    { label: t('since2years'), value: 24 },
    { label: t('sinceBeginning'), value: 120 },
  ];

  const handleSinceDateChange = (evt: SelectChangeEvent<unknown>) => {
    setSinceDate(evt.target.value as number);
  };

  const handleCardclick = async (invoice) => {
    const downloadUrl = await getDownloadUrl(invoice.storagePath);
    window.open(downloadUrl);
  };

  return (
    <>
      <PageBlock>
        <SectionWrapper>
          <h1>{t('pageTitle', { ns: 'voucherAccounting' })}</h1>
          <h2 className={'text-base font-normal'}>{t('pageSubtitle', 'voucherAccounting')}</h2>
          <div className={'flex justify-between mt-6 pb-4'}>
            <Select
              className={'bg-primary text-white text-center'}
              value={sinceDate}
              onChange={(evt) => {
                handleSinceDateChange(evt);
              }}
              labelId="label"
              id="select"
            >
              {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
            <div>
              <InputField
                placeholder={t('invoiceSearchPlaceholder')}
                onChange={(evt) => setSearch(evt.target.value)}
                value={search}
                size={'small'}
                startIcon={<GlassesSvg/>}
              />
            </div>
          </div>

          {filteredInvoices &&
            <div className={'flex flex-wrap gap-6'}>
              {filteredInvoices?.map((invoice) =>
                <VoucherInvoiceCard key={invoice.uid} invoice={invoice}
                                    onClick={() => handleCardclick(invoice)}/>,
              )}
            </div>
          }
        </SectionWrapper>
      </PageBlock>
    </>
  );
}));

export default VouchersInvoicesComponent;