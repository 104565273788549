import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { FunctionComponent, useCallback, useEffect } from 'react';
import { columnsBeneficiaries } from './DataGridBeneficiaries';
import { useTranslation } from 'react-i18next';
import { provider, useInstances } from 'react-ioc';
import { BeneficiaryModel } from '@assets/models/beneficiaries/Beneficiary.model';
import { useFeatureFlag } from '../Hooks/useFeatureFlag';
import { AgenciesStore } from '../Stores/Agencies.store';
import { BeneficiariesStore } from '../Stores/Beneficiaries.store';
import { BeneficiariesViewStore } from '../Stores/viewStore/BeneficiariesView.store';
import AddBeneficiariesByUploadComponent from './addBeneficiariesByUpload/AddBeneficiariesByUploadComponent';
import { isBeneficiaryActiveToday } from '../assets/utils/beneficiaries/isBeneficiaryActive.util';
import CreateNewBeneficiaryComponent from './BeneficiaryDetails/CreateNewBeneficiary/CreateNewBeneficiaryComponent';
import { BeneficiariesUploadStore } from '../Stores/BeneficiariesUpload.store';
import { PageBlock } from 'ui/layout/PageBlock';
import { Button } from '../ui/Buttons/Button';
import { Dialog } from '../ui/Dialog';
import { SectionWrapper } from '../ui/layout/SectionWrapper';
import { InputField } from '../ui/Input';
import { ReactComponent as SearchSvg } from '../assets/svg/glasses.svg';
import { DataSpreadsheet } from '../ui/DataSpreadsheet';
import BeneficiaryDetails from './BeneficiaryDetails/BeneficiaryDetails/BeneficiaryDetails';

const BeneficiariesPage: FunctionComponent = provider(
  BeneficiariesViewStore,
  BeneficiariesStore,
)(observer(() => {
  const [
    agenciesStore,
    beneficiariesViewStore,
    beneficiariesStore,
    beneficiariesUploadStore,
  ] = useInstances(AgenciesStore, BeneficiariesViewStore, BeneficiariesStore, BeneficiariesUploadStore);

  const beneficiariesList: BeneficiaryModel[] = beneficiariesStore.beneficiariesList;
  const showInactiveBeneficiaries: boolean = beneficiariesViewStore.showInactiveBeneficiaries;
  const openDialog: boolean = beneficiariesViewStore.openDialog;
  const { t } = useTranslation('beneficiaries');
  const [openDrawerCreate, setOpenDrawerCreate] = React.useState<boolean>(false);
  const [openDrawerUpload, setOpenDrawerUpload] = React.useState<boolean>(false);

  const { enabled: isUploadEnabled } = useFeatureFlag('uploadBeneficiaries', false);

  useEffect(() => {
    if (agenciesStore.currentAgency && (!openDialog || !openDrawerCreate || beneficiariesUploadStore.beneficiariesListsDirty)) {
      beneficiariesStore.fetchAllBeneficiariesByAgencyId(agenciesStore.currentAgency.uid);

      if (beneficiariesUploadStore.beneficiariesListsDirty) {
        beneficiariesUploadStore.resetBeneficiariesListDirty();
      }
    }
  }, [agenciesStore.currentAgency, openDialog, openDrawerCreate, beneficiariesUploadStore.beneficiariesListsDirty]);

  useEffect(() => {
    if (!openDialog) {
      const searchBeneficiariesToLowerCase = beneficiariesViewStore.inputFilter.toLowerCase();
      beneficiariesStore.filterBeneficiariesByString(showInactiveBeneficiaries, searchBeneficiariesToLowerCase);
    }
  }, [beneficiariesViewStore.inputFilter, showInactiveBeneficiaries, openDialog, beneficiariesList]);

  const handleOpenDrawerUpdate = (beneficiaryId: string) => {
    beneficiariesStore.fetchBeneficiaryById(beneficiaryId)
      .then(() => beneficiariesViewStore.setOpenDialog(true));
  };

  const handleCloseDrawerUpdate = useCallback(() => {
    beneficiariesViewStore.setOpenDialog(false);
    beneficiariesStore.setCurrentBeneficiary(null);
  }, []);

  const handleOpenDrawerCreate = useCallback(() => {
    setOpenDrawerCreate(true);
  }, []);

  const handleCloseDrawerCreate = useCallback(() => {
    setOpenDrawerCreate(false);
  }, []);

  const handleCloseDrawerUpload = useCallback(() => {
    setOpenDrawerUpload(false);
  }, []);

  const handleOpenDrawerUpload = useCallback(() => {
    setOpenDrawerUpload(true);
  }, []);

  return (
    <>
      <PageBlock>
        <SectionWrapper className={'space-y-4'}>
          <h1 className={''}>{t('title')}</h1>
          <div>
            {t('subtitlePartOne', { count: beneficiariesList.length })}
            {beneficiariesStore.inactiveBeneficiaryList.length !== 0 &&
              <span>
          &nbsp;{t('subtitlePartTwo', { count: beneficiariesStore.inactiveBeneficiaryList.length })}
                <div className={'text-primary cursor-pointer inline hover:underline hover:text-primary/50'}
                     onClick={() => beneficiariesViewStore.setShowInactiveBeneficiaries(!showInactiveBeneficiaries)}>
                {showInactiveBeneficiaries ? t('hidden') : t('display')}
              </div>)
            </span>
            }
          </div>
          <div className={'flex justify-between'}>
            <div className={'flex gap-4'}>
              <Button onClick={handleOpenDrawerCreate} className={'h-fit'}>
                {t('addBeneficiary')}
              </Button>
              {isUploadEnabled &&
                <Button onClick={handleOpenDrawerUpload} className={'h-fit'}>
                  Ajouter / mettre à jour plusieurs collaborateurs par fichier
                </Button>}
            </div>
              <InputField
                size={'small'}
                placeholder={t('searchPlaceholder')}
                startIcon={<SearchSvg/>}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => beneficiariesViewStore.setInputFilter(e.target.value)}
                value={beneficiariesViewStore.inputFilter}
              />
            </div>
          <DataSpreadsheet
            rows={toJS(beneficiariesStore.beneficiariesFilteredList.map((beneficiary: BeneficiaryModel) => ({
              ...beneficiary,
              isActive: isBeneficiaryActiveToday(beneficiary),
            })))}
            loading={!agenciesStore.isCurrentAgencyLoaded || beneficiariesStore.isLoading}
            columns={columnsBeneficiaries}
            disableRowSelectionOnClick
            onRowClick={(params) => !beneficiariesStore.isLoading && handleOpenDrawerUpdate(params.row.uid)}
          />
        </SectionWrapper>

      </PageBlock>
      <Dialog open={openDialog} onClose={handleCloseDrawerUpdate}>
        <BeneficiaryDetails
          beneficiary={beneficiariesStore.currentBeneficiary}
          agency={agenciesStore.currentAgency}
          onClose={handleCloseDrawerUpdate}
        />
      </Dialog>

      <Dialog open={openDrawerCreate} onClose={handleCloseDrawerCreate}>
        <CreateNewBeneficiaryComponent onClose={handleCloseDrawerCreate}/>
      </Dialog>

      <Dialog open={openDrawerUpload} onClose={handleCloseDrawerUpload}>
        <AddBeneficiariesByUploadComponent onClose={handleCloseDrawerUpload}/>
      </Dialog>
    </>
  );
}));

export default BeneficiariesPage;
