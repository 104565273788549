import { observer } from 'mobx-react-lite';
import React, { FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useInstances } from 'react-ioc';
import { ReactComponent as UploadSvg } from '../../assets/svg/file-alt.svg';
import { ReactComponent as HandingSvg } from '../../assets/svg/file-alt-keyboard.svg';
import { useFeatureFlag } from '../../Hooks/useFeatureFlag';
import { StepperRechargingStore } from '../../Stores/viewStore/StepperRecharging.store';
import { RechargingViewStore } from '../../Stores/viewStore/RechargingView.store';
import { AgenciesStore } from '../../Stores/Agencies.store';
import { Button } from '../../ui/Buttons/Button';
import { TStepAddNewRecharging } from '../../Models/Types/TStepAddNewRecharging.model';
import clsx from 'clsx';

type LocalStore = [AgenciesStore, StepperRechargingStore, RechargingViewStore];

interface Item {
  button: string;
  path: TStepAddNewRecharging,
  active: boolean;
}

const SelectMethodRecharging: FunctionComponent = observer(() => {
  const [agenciesStore, stepperRechargingStore, rechargingViewStore]: [AgenciesStore, StepperRechargingStore, RechargingViewStore] = useInstances<LocalStore>(AgenciesStore, StepperRechargingStore, RechargingViewStore);
  const { t } = useTranslation('reloading');

  const { enabled: isUploadEnabled } = useFeatureFlag('uploadBeneficiariesRecharging');
  const { isCreateOrderLocked } = agenciesStore;

  const items: Item[] = [
    {
      button: t('selectMethod.uploadMethod'),
      path: 'ADD_NEW_BENEFICIARIES_UPLOAD',
      active: isUploadEnabled,
    },
    {
      button: t('selectMethod.manualMethod'),
      path: 'PARAMETERS',
      active: true,
    }
  ];

  useEffect(() => {
    if (!sessionStorage.getItem('reloadingDateKey')) {
      rechargingViewStore.setInitialData();
    }
  }, []);

  return (
    <>
      <h1 className={''}>{t('titleChoice')}</h1>
      <h2 className={'text-center text-xl py-24'}>
        {isCreateOrderLocked ? 'Un rechargement est déjà en cours, veuillez patienter...' : t('subTitleChoice')}
      </h2>
      <div className={'flex justify-center gap-14'}>
        {
          items.map((item: Item, index: number) => (
            <div key={index} className={clsx('group hover:bg-background transition-all duration-300 ease-in-out w-72 h-72 px-3 py-5 rounded-br10 shadow-card flex flex-col justify-between',
              item.active ? 'block' : 'hidden')}>
              <div className={'flex flex-1 justify-center items-center'}>
                {item.path === 'PARAMETERS' && <HandingSvg className={'w-14'} />}
                {item.path === 'ADD_NEW_BENEFICIARIES_UPLOAD' && <UploadSvg className={'w-14'} />}
              </div>
              <Button
                variant="contained"
                className={'group-hover:bg-primary/90'}
                disabled={isCreateOrderLocked}
                fullWidth
                onClick={() => {
                  stepperRechargingStore.goToStep(item.path);
                }}
                color="primary">
                {item.button}
              </Button>
            </div>
          ))
        }
      </div>
    </>
  );
});

export default SelectMethodRecharging;
