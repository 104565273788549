import React, { FC } from 'react';
import { Select as MuiSelect, SelectProps, FormControl, OutlinedInput } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowSvg } from './../assets/svg/arrows/arrowRight.svg';
import { FormikProps } from 'formik';

const CLASSES = {
  root: 'group',
  label: {
    wrapper: 'absolute top-2 left-0 z-10 px-6 w-full flex justify-between text-primary/50 text-sm',
  },
  input: {
    base: clsx(
      'text-sm rounded-br30 bg-background h-16',
      'disabled:opacity-50 text-primary',
      'border border-solid border-background',
      'hover:border-primary focus:border-primary',
      'transition-all duration-200 pl-2.5',
      'cursor-pointer w-full'
    ),
    withLabel: 'pt-6',
    defaultWidth: 'w-56',
    error: '!border-status-error hover:!border-status-error',
  },
  muiClasses: {
    notchedOutline: 'border-none',
    focused: 'ring-2 ring-primary ring-opacity-50',
  },
  arrow: {
    base: 'w-4 h-4 mr-4 -rotate-90 transition-all duration-300',
    open: 'rotate-90',
  },
  errorMessage: 'pt-1 text-sm text-status-error',
};

type BaseSelectProps = Omit<SelectProps, 'size'> & {
  size?: 'small' | 'medium' | 'default';
  label?: string;
  errorMessage?: string;
};

interface FormikSelectProps extends Omit<BaseSelectProps, 'value' | 'onChange' | 'onBlur'> {
  formik: FormikProps<any>;
  name: string;
}

interface MultiSelectProps extends Omit<BaseSelectProps, 'multiple'> {
  renderValue?: (selected: unknown[]) => React.ReactNode;
}

interface FormikMultiSelectProps extends Omit<MultiSelectProps, 'value' | 'onChange' | 'onBlur'> {
  formik: FormikProps<any>;
  name: string;
}

export const Select: FC<BaseSelectProps> = (props) => {
  const {
    label,
    className,
    size = 'default',
    fullWidth,
    children,
    required,
    error,
    errorMessage,
    ...rest
  } = props;
  const { t } = useTranslation('common');

  return (
    <FormControl fullWidth={fullWidth} required={required} className={CLASSES.root}>
      {label && (
        <div className={CLASSES.label.wrapper}>
          <div>{label}</div>
          {required && <div>{t('required')}</div>}
        </div>
      )}

      <MuiSelect
        {...rest}
        error={error}
        variant="outlined"
        input={
          <OutlinedInput
            className={clsx(
              CLASSES.input.base,
              label && CLASSES.input.withLabel,
              !fullWidth && CLASSES.input.defaultWidth,
              error && CLASSES.input.error,
              className,
            )}
            classes={CLASSES.muiClasses}
          />
        }
        IconComponent={(props) => (
          <ArrowSvg
            className={clsx(
              CLASSES.arrow.base,
              CLASSES.arrow.open
            )}
          />
        )}
      >
        {children}
      </MuiSelect>

      {error && errorMessage && (
        <p className={CLASSES.errorMessage}>{errorMessage}</p>
      )}
    </FormControl>
  );
};

export const FormikSelect: FC<FormikSelectProps> = ({ formik, name, ...props }) => {
  const error = formik.touched[name] && formik.errors[name];

  return (
    <Select
      {...props}
      name={name}
      value={formik.values[name]}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      error={Boolean(error)}
      errorMessage={error as string}
    />
  );
};

export const MultiSelect: FC<MultiSelectProps> = (props) => {
  return (
    <Select
      {...props}
      multiple
      value={props.value || []}
    />
  );
};

export const FormikMultiSelect: FC<FormikMultiSelectProps> = ({ formik, name, ...props }) => {
  const error = formik.touched[name] && formik.errors[name];

  return (
    <MultiSelect
      {...props}
      name={name}
      value={formik.values[name]}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      error={Boolean(error)}
      errorMessage={error as string}
    />
  );
};