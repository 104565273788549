import { provider, useInstances } from 'react-ioc';
import { observer } from 'mobx-react-lite';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import React from 'react';
import { AgencyAdminStore } from '../../Stores/AgencyAdmin.store';
import { AgenciesStore } from '../../Stores/Agencies.store';
import { UserConnectStore } from '../../Stores/UserConnect.store';
import { MandateStore } from '../../Stores/Mandate.store';
import { AgencyInvoiceStore } from '../../Stores/AgencyInvoice.store';
import { HoverDropdown } from '../../ui/HoverDropdown';
import { capitalizeFirstLetter } from '../../Utils/string.utils';

type LocalStore = [AgencyAdminStore, AgenciesStore, UserConnectStore, MandateStore, AgencyInvoiceStore];

const RightBlock = provider()(observer(() => {
  const [
    agencyAdminStore,
    agenciesStore,
    userConnectStore,
    mandateStore,
    agencyInvoiceStore,
  ]: LocalStore = useInstances<LocalStore>(AgencyAdminStore, AgenciesStore, UserConnectStore, MandateStore, AgencyInvoiceStore);

  const navigate: NavigateFunction = useNavigate();

  const handleClickLogout = async () => {
    await userConnectStore.userLogout();
    agencyAdminStore.resetAgencyAdmin();
    agenciesStore.reset();
    mandateStore.reset();
    agencyInvoiceStore.reset();
    navigate('/login');
  };

  return (
    <div className="group flex justify-center items-center cursor-pointer">
      <div className="relative flex flex-row">
        <div className="flex flex-col justify-center">
          <div
            className="flex justify-center items-center w-14 h-14 bg-green rounded-full font-bold text-large group-hover:bg-green/50 mr-4">
            <p>{agencyAdminStore.agencyAdminSelected ?
              `${agencyAdminStore.agencyAdminSelected?.firstName[0].toUpperCase()}${agencyAdminStore.agencyAdminSelected?.lastName[0].toUpperCase()}`
              : ''
            }</p>
          </div>
        </div>
        <div className="flex flex-col justify-center gap-y-1 align-middle">
          <p className="font-semibold text-sm text-center truncate">
            {`${capitalizeFirstLetter(agencyAdminStore.agencyAdminSelected?.firstName) ?? ''} ${agencyAdminStore.agencyAdminSelected?.lastName.toUpperCase() ?? ''}`}
          </p>
          <p className={'mx-auto bg-white rounded flex items-center justify-center py-1 px-2 w-fit text-xs'}>
            Administrateur
          </p>
        </div>
        <div className="flex flex-col justify-center">
          <HoverDropdown childrenPosition={'right'}>
            <ul
              className="bg-white shadow-2xl text-primary rounded-br10 px-0 m-0 mt-2 py-4 space-y-2">
              <li onClick={handleClickLogout}
                  className="px-4 h-10 text-sm flex items-center justify-start cursor-pointer hover:bg-green/50 transition-colors">
                <p>Déconnexion</p>
              </li>
            </ul>
          </HoverDropdown>
        </div>
      </div>
    </div>
  );
}));

export default RightBlock;
