import { DeactivationPolicyType } from '../../../assets/models/beneficiaries/Beneficiary.model';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { getEndOfMillesimeDate } from '../../../assets/utils/millesime/millesime.util';
import { useNavigate } from 'react-router';
import { NavigateFunction } from 'react-router-dom';
import { getTimeStampEndOfDay } from '../../../assets/utils/dates/getParsedDate.util';
import {
  ManageBeneficiaryDeactivationScheduleRequest,
  EnableBeneficiaryDeactivationScheduleRequest,
  DisableBeneficiaryDeactivationScheduleRequest,
} from '../../../assets/requests/beneficiaries/ManageBeneficiaryDeactivationSchedule.request';
import { obfuscateIbanNumbersUtil } from '../../../assets/utils/databank/obfuscateIbanNumbers.util';
import { useTranslation } from 'react-i18next';
import {
  allowToModifyBeneficiaryDeactivationConfig,
} from '../../../assets/utils/beneficiaries/beneficiaryDeactivation.util';
import { FormControlLabel, Radio } from '@mui/material';
import { IBeneficiaryDetailsProps } from '../../../Models/Interfaces/IBeneficiaryDetailsProps.model';
import { useInstances } from 'react-ioc';
import { BeneficiariesStore } from '../../../Stores/Beneficiaries.store';
import { convertCentToEuro } from './../../../assets/utils/functions/convertCentToEuro.util';
import { ReactComponent as WarningSvg } from '../../../assets/svg/warning.svg';
import { ReactComponent as CalendarSvg } from '../../../assets/svg/calendar.svg';
import { ReactComponent as WalletSvg } from '../../../assets/svg/wallet.svg';
import { SwitchWithLabel } from '../../../ui/Switch';
import { InputField } from '../../../ui/Input';
import clsx from 'clsx';
import { Button } from '../../../ui/Buttons/Button';
import { Toaster } from '../../../ui/Toaster';

type LocalStore = [BeneficiariesStore];

export interface IFormErrors {
  deactivationDate?: string;
  deactivationPolicy?: string;
  haveAbandonedRights?: boolean;
  consumptionRightLimitDate?: string;
}

const timestampToDateString = (timestamp: number): string => {
  return new Date(timestamp).toISOString().replace(/T.+$/, '');
};

const BeneficiaryTakeOutComponent: FunctionComponent<IBeneficiaryDetailsProps> = observer((props: IBeneficiaryDetailsProps) => {
  const [beneficiariesStore]: LocalStore = useInstances<LocalStore>(BeneficiariesStore);

  const { beneficiary, agency }: IBeneficiaryDetailsProps = props;
  const beneficiaryId: string = beneficiary.uid;
  const initialValues: {
    deactivationDate: string,
    deactivationPolicy: DeactivationPolicyType,
    consumptionRightLimitDate: string
  } = useMemo(() => ({
    deactivationDate: beneficiary.deactivationDate ? timestampToDateString(beneficiary.deactivationDate) : timestampToDateString(Date.now()),
    consumptionRightLimitDate: beneficiary.consumptionRightLimitDate ? timestampToDateString(beneficiary.consumptionRightLimitDate) : '',
    deactivationPolicy: beneficiary.deactivationPolicy || 'ABANDON_RIGHTS',
  }), [beneficiary]);

  const disableModification: boolean = useMemo(() => {
    return !allowToModifyBeneficiaryDeactivationConfig(beneficiary);
  }, [beneficiary]);

  const standardContract: boolean = useMemo(() => {
    return ['STANDARD', 'GEOGRAPHIC_RESTRICTION'].includes(agency?.contractType);
  }, [agency]);

  const { t } = useTranslation('beneficiaryTakeOut');
  const [deactivationSwitchChecked, setDeactivationSwitchChecked] = useState<boolean>(!!beneficiary.deactivationDate);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [hasRestitutionFailed, setHasRestitutionFailed] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<DeactivationPolicyType>(beneficiary.deactivationPolicy || 'ABANDON_RIGHTS');
  const [formErrors, setFormErrors] = useState<IFormErrors>({});
  const [formValues, setFormValues] = useState(initialValues);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const navigate: NavigateFunction = useNavigate();

  const hasChanges: boolean = useMemo(() => {
    return deactivationSwitchChecked !== !!beneficiary.deactivationDate
      || formValues.deactivationDate !== initialValues.deactivationDate
      || formValues.consumptionRightLimitDate !== initialValues.consumptionRightLimitDate
      || formValues.deactivationPolicy !== initialValues.deactivationPolicy;
  }, [beneficiary, formValues, deactivationSwitchChecked]);

  const generateRestitutionStatement = useMemo(() => {
    if (agency.contractType === 'PUBLIC_MEAL_SUBSIDY') {
      return <></>;
    }

    setHasRestitutionFailed(beneficiary.deactivationDetails?.state === 'PENDING');
    if (beneficiary.deactivationDetails?.state === 'PENDING') {
      return <p>{`Il n’y avait plus assez de provisions dans votre portefeuille Openeat le ${new Date(beneficiary.deactivationDetails?.lastExecutionDate).toLocaleDateString() || 'Date non valide'} pour vous restituer les ${beneficiary.deactivationDetails?.restitutionAmount?.toFixed(2)} € du solde non consommé du bénéficiaire`}</p>;
    }
    if (!beneficiary.deactivationDetails?.restitutionAmount || beneficiary?.deactivationDetails?.restitutionAmount === 0) {
      return <p>Le solde du collaborateur était de 0,00 € et n’a pas donné lieu à une restitution.</p>;
    } else {
      const formattedAmount = `${convertCentToEuro(beneficiary.deactivationDetails?.restitutionAmount) || ''} €.`;
      let statement =
        <span>L'entreprise a récupéré les droits non consommés par le collaborateur soit {formattedAmount}</span>;

      switch (beneficiary.deactivationDetails.restitutionMethod) {
        case 'DIRECT_DEBIT_IBAN_PAYOUT':
          return (
            <>
              {statement}
              <br/>
              <p className={'py-2'}>Virement sur le compte de prélèvement.</p>
            </>
          );
        case 'RESTITUTION_IBAN_PAYOUT':

          return (
            <>
              {statement}
              <br/>
              <p className={'py-2'}>Virement sur le compte de
                l'IBAN {obfuscateIbanNumbersUtil(beneficiary.deactivationDetails.restitutionIban)}.</p>
            </>
          );
        case 'PROVISIONS_WALLET_DEPOSIT':
          return (
            <>
              {statement}
              <br/>
              <p className={'py-2'}>Le montant de ces droits est conservé sur le portefeuille de provision.</p>
            </>
          );
        default:
          return statement;
      }
    }
  }, [beneficiary, beneficiary?.deactivationDetails?.restitutionMethod, agency]);

  const validateSubmitedForm = () => {
    const deactivationDateValue: string = formValues.deactivationDate;
    const consumptionRightLimitDateValue: string = formValues.consumptionRightLimitDate;

    const deactivationDate: Date = new Date(deactivationDateValue);
    const consumptionRightLimitDate: Date = new Date(consumptionRightLimitDateValue);

    const deactivationDateTime: number = deactivationDate.getTime();
    const consumptionRightLimitDateTime: number = consumptionRightLimitDate.getTime();

    const endMillesimeDate: Date = getEndOfMillesimeDate(deactivationDate);
    const endMillesimeDateTime: number = endMillesimeDate.getTime();

    if (!deactivationSwitchChecked) {
      return false;
    }

    if (!deactivationDate) {
      setFormErrors({
        deactivationDate: 'La date de désactivation est requise',
      });
      return true;
    } else if (consumptionRightLimitDateTime > endMillesimeDateTime) {
      setFormErrors({
        consumptionRightLimitDate: `Cette date ne peut être supérieure à la fin de millésime (${timestampToDateString(endMillesimeDateTime)}).`,
      });
      return true;
    } else if (consumptionRightLimitDateTime < deactivationDateTime) {
      setFormErrors({
        consumptionRightLimitDate: 'Cette date doit être supérieure ou égale à la date de sortie du dispositif',
      });
      return true;
    } else if (selectedOption === 'KEEP_RIGHTS' && !consumptionRightLimitDateValue) {
      setFormErrors({
        consumptionRightLimitDate: 'Ce champs est requis',
      });
      return true;
    }

    return false;
  };

  useEffect(() => {
    const deactivationDate: Date = new Date(formValues.deactivationDate);
    const consumptionRightLimitDate: Date = new Date(formValues.consumptionRightLimitDate);

    const consumptionRightLimitDateTime: number = consumptionRightLimitDate.getTime();

    const endMillesimeDate: Date = getEndOfMillesimeDate(deactivationDate);
    const endMillesimeDateTime: number = endMillesimeDate.getTime();

    if (consumptionRightLimitDateTime > endMillesimeDateTime) {
      setFormErrors({
        consumptionRightLimitDate: `Cette date ne peut être supérieure à la fin de millésime (${timestampToDateString(endMillesimeDateTime)}).`,
      });
      return;
    }
    setFormErrors({});
  }, [formValues.consumptionRightLimitDate, formValues.deactivationDate, deactivationSwitchChecked]);

  const handleSubmit = useCallback(async (e) => {
    if (isSaving) {
      return;
    }
    e.preventDefault();
    const hasFormErrors = validateSubmitedForm();

    if (!hasFormErrors) {
      let formattedRequest: ManageBeneficiaryDeactivationScheduleRequest;

      if (deactivationSwitchChecked) {
        formattedRequest = {
          enableDeactivation: true,
          deactivationDate: new Date(formValues.deactivationDate).getTime(),
          deactivationPolicy: formValues.deactivationPolicy,
          consumptionRightLimitDate: formValues.deactivationPolicy === 'KEEP_RIGHTS' && formValues.consumptionRightLimitDate
            ? getTimeStampEndOfDay(new Date(formValues.consumptionRightLimitDate).getTime())
            : undefined,
        } as EnableBeneficiaryDeactivationScheduleRequest;
      } else {
        formattedRequest = { enableDeactivation: false } as DisableBeneficiaryDeactivationScheduleRequest;
      }

      setIsSaving(true);
      await beneficiariesStore.manageBeneficiaryDeactivationSchedule(beneficiaryId, formattedRequest);
      if (beneficiariesStore.updateBeneficiaryDeactivationStatus === 'SUCCESS') {
        setShowSnackbar(true);
      } else {
        resetFormValues();
      }
      setIsSaving(false);
    }
    return;
  }, [formValues, deactivationSwitchChecked]);

  const resetFormValues = useCallback(() => {
    setFormValues(initialValues);
    setFormErrors({});
    setDeactivationSwitchChecked(!!beneficiary.deactivationDate);
    setSelectedOption(beneficiary.deactivationPolicy || 'ABANDON_RIGHTS');
    setHasRestitutionFailed(false);
  }, [beneficiary, initialValues]);

  return (
    <>
      <form>
        <div className={'flex flex-col py-5'}>
          {standardContract &&
              <div className={'bg-status-info flex items-center rounded-br10 gap-2 p-5'}>
                  <WarningSvg title={'icon-warning'}/>
                {!disableModification
                  ? (<p className={'text-sm'}>
                    En sortant le collaborateur du dispositif, vous ne pourrez plus commander pour ce bénéficiaire.<br/>
                    Cette action est définitive.
                  </p>)
                  : (<p className={'text-sm'}>Ce collaborateur est sortit du dispositif, il ne
                    sera pas présent dans la liste des bénéficiaires de
                    vos prochaines commandes.
                  </p>)
                }
              </div>
          }

          <div className={'flex justify-between space-y-4'}>
            <SwitchWithLabel
              label={'Sortir le collaborateur du dispositif'}
              disabled={disableModification}
              checked={deactivationSwitchChecked}
              onChange={() => setDeactivationSwitchChecked(!deactivationSwitchChecked)}
            />
            <div className={clsx('w-1/2', deactivationSwitchChecked ? 'visible' : 'invisible')}>
              <InputField
                disabled={disableModification}
                type={'date'}
                fullWidth
                endIcon={<CalendarSvg width={20}/>}
                onChange={(e) => {
                  setFormValues({ ...formValues, deactivationDate: e.target.value });
                }}
                label={'Date de sortie du dispositif'}
                required={!disableModification}
                value={formValues.deactivationDate}
              />
            </div>
          </div>

        </div>

        {(standardContract && deactivationSwitchChecked) && (
          <>
            <hr/>

            <div>
              <h3 className={'font-bold'}>
                Récupération du solde de collaborateur
              </h3>
              <div>
                <FormControlLabel control={(
                  <Radio
                    disabled={disableModification}
                    checked={selectedOption === 'ABANDON_RIGHTS'}
                    onClick={() => {
                      setSelectedOption('ABANDON_RIGHTS');
                      setFormValues({ ...formValues, deactivationPolicy: 'ABANDON_RIGHTS' });
                    }
                    }
                    className={'cursor-pointer text-primary mr-2'}
                    name="radio"
                    id={'radio'}
                    value={'ABANDON_RIGHTS'}
                  />)}
                                  label={<span className={'text-sm'}>Le collaborateur renonce à ses droits</span>}
                />
                <p className={'text-muted text-sm pl-10'}>
                  Le collaborateur n’aura plus accés à l’application Openeat à sa sortie du dispositif.
                </p>
              </div>

              <div className={'flex justify-between py-10'}>
                <div className={'w-1/2'}>
                  <FormControlLabel
                    control={<Radio
                      disabled={disableModification}
                      checked={selectedOption === 'KEEP_RIGHTS'}
                      className={'cursor-pointer text-primary mr-2'}
                      onChange={() => {
                        setSelectedOption('KEEP_RIGHTS');
                        setFormValues({ ...formValues, deactivationPolicy: 'KEEP_RIGHTS' });
                      }}
                      value={'KEEP_RIGHTS'}
                      name="radio"
                      id={'radio'}
                    />}
                    label={<span
                      className={'text-sm'}>Le collaborateur garde ses droits jusqu’à une certaine date</span>}
                  />
                  <p className={'text-muted text-sm pl-10'}>
                    Le collaborateur peut utiliser l’application Openeat et consommer son solde jusqu’à la date limite
                    de
                    consommation des droits.
                  </p>
                </div>
                <div className={clsx('w-1/2', selectedOption === 'KEEP_RIGHTS' ? 'visible' : 'invisible')}>
                  <InputField
                    type="date"
                    fullWidth
                    endIcon={<CalendarSvg width={20}/>}
                    onChange={(e) => setFormValues({ ...formValues, consumptionRightLimitDate: e.target.value })}
                    label={'Date limite de consommation des droits'}
                    required={!disableModification}
                    value={formValues.consumptionRightLimitDate}
                    disabled={disableModification}
                    error={Boolean(formErrors.consumptionRightLimitDate)}
                    errorMessage={formErrors.consumptionRightLimitDate}
                  />
                </div>
              </div>
            </div>
          </>
        )}

        {(standardContract && disableModification) && (
          <div
            className={clsx('min-h-10 p-5 rounded-br10 flex items-center gap-2', hasRestitutionFailed ? 'bg-status-error' : 'bg-background')}>
            {hasRestitutionFailed ? <WarningSvg width={20}/> : <WalletSvg/>}
            <div className={'text-sm'}>{generateRestitutionStatement}</div>
          </div>
        )}

        {(standardContract && deactivationSwitchChecked) && (
          <p onClick={() => navigate('/agency')} className={'text-sm text-link underline cursor-pointer hover:text-link/75'}>
            Accéder aux paramètres de remboursement de l'entreprise
          </p>
        )}

        <div className={'flex justify-end gap-4 pt-10'}>
          {hasChanges && (
            <>
              <Button
                onClick={resetFormValues}
                disabled={!hasChanges || isSaving}
                variant={'outlined'}
              >
                Non, annuler
              </Button>

              <Button
                onClick={handleSubmit}
                loading={isSaving}
                disabled={!hasChanges || isSaving}
              >
                Oui, je confirme
              </Button>
            </>
          )}
        </div>

        <Toaster open={showSnackbar} onClose={() => setShowSnackbar(false)}>
          <>{t('confirmation')}</>
        </Toaster>
      </form>
    </>
  );
});
export default BeneficiaryTakeOutComponent;