import { GridColDef } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowBracketSvg } from '../../../assets/svg/arrowBracket.svg';
import { ManagementUnitModel } from '@assets/models/agencies/ManagementUnit.model';
import { AgencyAdminSettingModel } from '@assets/models/agencyAdmin/AgencyAdmin.model';
import { NavigateFunction } from 'react-router-dom';

function compareManagementUnitLists(list1: ManagementUnitModel[], list2: ManagementUnitModel[]): boolean {
  if (list1.length !== list2.length) {
    return false;
  }

  const sortByUid = (a: ManagementUnitModel, b: ManagementUnitModel) => {
    return a.uid.localeCompare(b.uid);
  };

  const sortedList1: ManagementUnitModel[] = list1.slice().sort(sortByUid);
  const sortedList2: ManagementUnitModel[] = list2.slice().sort(sortByUid);

  return sortedList1.every((unit, index) => unit.uid === sortedList2[index].uid);
}

const handleUpdateAgencyAdmin = (agencyAdmin: AgencyAdminSettingModel, navigate) => {
  navigate('/settings/add-agencyadmin', {
    state: {
      agencyAdminData: {
        id: agencyAdmin.uid,
        email: agencyAdmin.email,
        firstName: agencyAdmin.firstName,
        lastName: agencyAdmin.lastName,
        profile: agencyAdmin.profile.profileId,
        managementUnits: agencyAdmin.managementUnits.map((unit) => ({
          label: unit.name,
          value: unit.uid,
        })),
        agencyAdminId: agencyAdmin.uid,
      },
    },
  });
};

export const AgencyAdminColumns: GridColDef[] = [
  {
    field: 'email',
    flex: 1,
    renderHeader: () => {
      const { t } = useTranslation('common');
      return (
        <p className={'font-bold text-sm'}>{t('email')}</p>
      );
    },
  },
  {
    field: 'name',
    flex: 1,
    renderHeader: () => {
      const { t } = useTranslation('common');
      return (
        <p className={'font-bold text-sm'}>{t('name')}</p>
      );
    },
  },
  {
    field: 'profile',
    flex: 1,
    renderHeader: () => {
      const { t } = useTranslation('settings');
      return (
        <p className={'font-bold text-sm'}>{t('profile')}</p>
      );
    },
  },
  {
    field: 'managementUnits',
    flex: 1,
    renderHeader: () => {
      const { t } = useTranslation('settings');

      return (
        <p className={'font-bold text-sm'}>{t('managementUnits')}</p>
      );
    },
    renderCell: (params) => {
      const { t } = useTranslation('common');

      return (
        <div> {params.value ?
          <p className={'inline text-primary px-2 py-1 rounded-lg mr-2 bg-accent'}>{t('all_females')}</p> :
          params.value.length > 1 ?
            <>
              <p className={'inline bg-green text-primary px-2 py-1 rounded-lg mr-2'}>{params.value[0].name}</p>
              <p className={'inline bg-green text-primary px-2 py-1 rounded-lg mr-2'}>+{params.value.length - 1}</p>
            </>
            :
            <span className={'inline bg-green text-primary px-2 py-1 rounded-lg mr-2'}>{params.value[0].name}</span>
        }
        </div>
      );
    },
  },
  {
    field: 'arrow',
    headerName: '',
    display: 'flex',
    renderCell: () => <ArrowBracketSvg width={14} className={'self-center m-auto'}/>,
  },
];

export const AgencyAdminRows = (admins: AgencyAdminSettingModel[], currentAgencyManagementUnits: ManagementUnitModel[]) => {
  return admins.map((admin: AgencyAdminSettingModel) => {
    const managementUnits: ManagementUnitModel[] = compareManagementUnitLists(
      admin.managementUnits,
      currentAgencyManagementUnits,
    ) ? [] : admin.managementUnits;

    return {
      id: admin.uid,
      email: admin.email,
      name: admin.firstName + ' ' + admin.lastName.toUpperCase(),
      profile: admin.profile.profileName,
      managementUnits: managementUnits,
      onclick: (navigate: NavigateFunction) => handleUpdateAgencyAdmin(admin, navigate),
    };
  });
};
