import React, { FC } from 'react';
import { Dialog as MuiDialog, DialogProps } from '@mui/material';

type Pos = 'center' | 'right' | 'left';

interface Props extends DialogProps {
  position?: Pos;
}

export const Dialog: FC<Props> = (props) => {
  const { children, fullWidth = true, maxWidth = 'md', position = 'right', ...rest } = props;
  return (
    <MuiDialog
      {...rest}
      maxWidth="md"
      fullWidth
      sx={{
        '& .MuiBackdrop-root': {
          transition: 'opacity 225ms, backdrop-filter 225ms !important',
          backdropFilter: 'blur(5px)',
        },
        '& .MuiDialog-paper': {
          margin: 0,
          ...(position === 'right' && {
            marginLeft: 'auto',
            marginRight: 0,
            transform: 'none',
          }),
          ...(position === 'center' && {
            margin: '0 auto',
            transform: 'none',
          }),
          ...(position === 'left' && {
            marginLeft: 0,
            marginRight: 'auto',
            transform: 'none',
          }),
        },
        '& .MuiDialog-scrollPaper': {
          justifyContent: 'flex-end !important'
        },
        '& .MuiDialog-paperScrollPaper': {
          maxHeight: '100vh !important'

        }
      }}
    >
      {children}
    </MuiDialog>
  );
};
