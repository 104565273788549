import { MenuItem, SelectChangeEvent } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InvoiceModel } from '../../../assets/models/invoices/invoice.model';
import { AccountingViewStore } from '../../../Stores/viewStore/AccountingView.store';
import InvoiceDetailsComponent from './InvoiceDetails/InvoiceDetailsComponent';

import { ReactComponent as GlassesSvg } from '../../../assets/svg/glasses.svg';

import { Select } from 'ui/Select';
import { Dialog } from '../../../ui/Dialog';
import { Spinner } from '../../../ui/Spinner';
import { InputField } from '../../../ui/Input';
import InvoiceCard from '../../../Component/Cards/InvoiceCard';

interface Option {
  label: string;
  value: number;
}

const InvoicesComponent: FunctionComponent<any> = observer(({ accountingStore }: {
  accountingStore: AccountingViewStore,
}) => {
  const [filteredInvoices, setFilteredInvoices] = useState<InvoiceModel[]>([]);
  const [currentInvoiceKey, setCurrentInvoiceKey] = useState<number | undefined>(undefined);
  const [sinceDate, setSinceDate] = useState<number>(1);
  const { t } = useTranslation('accounting');

  const dateOptions = { year: 'numeric', month: 'long' } as const;

  const search: string = accountingStore.search;

  const applySearch = (newSearch: string, newInvoices: InvoiceModel[]) => {
    accountingStore.setSearch(newSearch);
    const filterInvoices = newInvoices.filter((i) => {
      const invoiceDate: Date = new Date(i.invoiceDate);
      const statusDate: Date = new Date(i.statusDate);
      const excludingTaxesAmount: number = i.feesAmountHT;
      const includingTaxesAmount: number = i.feesAmountTTC;

      return (
        (t('toPay', { mode: i.paymentMethod === 'DIRECT_DEBIT' ? 'prélèvement' : 'virement' }).toLocaleLowerCase().search(newSearch.toLocaleLowerCase()) !== -1 && i.statusDate) ||
        (`${i.paymentMethod === 'DIRECT_DEBIT' ? t('paidDirectDebit') : t('paidBankTransfer')}`.toLocaleLowerCase().search(newSearch.toLocaleLowerCase()) !== -1 && !i.statusDate) ||
        (i.statusDate && statusDate.toLocaleDateString().search(newSearch) !== -1) ||
        i.invoiceReference.toString().search(newSearch) !== -1 ||
        invoiceDate.toLocaleDateString('fr-FR', dateOptions).toLocaleLowerCase().search(newSearch) !== -1 ||
        includingTaxesAmount.toString().search(newSearch) !== -1 ||
        excludingTaxesAmount.toString().search(newSearch) !== -1
      );
    });
    setFilteredInvoices(filterInvoices);
  };

  const getInvoices = (from: Date) => {
    accountingStore.fetchAgencyInvoices(from)
      .then(() => {
        setFilteredInvoices(accountingStore.agencyInvoices);
        applySearch(search, accountingStore.agencyInvoices);
      })
      .catch(e => console.error(e));
  };

  useEffect(() => {
    if (accountingStore.agency) {
      const from = new Date();
      from.setFullYear(from.getFullYear() - 1);

      getInvoices(from);
    }

  }, [accountingStore.agency]);

  const handleClickOpen = (key: number) => {
    setCurrentInvoiceKey(key);
    accountingStore.setOpenDialog(true);
  };

  const handleClose = () => {
    accountingStore.setOpenDialog(false);
  };

  const options: Option[] = [
    { label: t('since6months'), value: 0 },
    { label: t('since1year'), value: 1 },
    { label: t('since2years'), value: 2 },
    { label: t('sinceBeginning'), value: 3 },
  ];

  const selectSinceDate: (value: number) => Date = useCallback((value: number) => {
    let from = new Date();
    switch (value) {
      case 0:
        from.setMonth(from.getMonth() - 6);
        break;
      case 1:
        from.setFullYear(from.getFullYear() - 1);
        break;
      case 2:
        from.setFullYear(from.getFullYear() - 2);
        break;
      case 3:
      default:
        from = null;
    }
    return from;
  }, []);

  const handleSinceDateChange = (evt: SelectChangeEvent<unknown>) => {
    setSinceDate(evt.target.value as unknown as number);
    getInvoices(selectSinceDate(sinceDate));
    accountingStore.setSinceDate(evt.target.value as string);
  };

  return (
    <div className={'space-y-4'}>
      {accountingStore.isLoadingAgencyInvoices
        ? <Spinner size={'lg'}/>
        : <>
          <div className={'flex justify-between mt-6'}>
            <Select
              className={'bg-primary text-white text-center'}
              value={sinceDate}
              onChange={(evt) => {
                handleSinceDateChange(evt);
              }}
              labelId="label"
              id="select"
            >
              {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
            <div>
              <InputField
                placeholder={t('invoiceSearchPlaceholder')}
                onChange={(evt) => applySearch(evt.target.value, accountingStore.agencyInvoices)}
                value={search}
                size={'small'}
                startIcon={<GlassesSvg/>}
              />
            </div>
          </div>
          {filteredInvoices &&
            <>
              <div className={'flex flex-wrap gap-6'}>
                {filteredInvoices?.map((invoice, i) =>
                  <InvoiceCard key={invoice.uid} invoice={invoice} onClick={() => handleClickOpen(i)}/>,
                )}
              </div>
              <Dialog className={''}
                      open={accountingStore.openDialog}
                      onClose={handleClose}
                      position={'center'}>
                <InvoiceDetailsComponent invoice={filteredInvoices[currentInvoiceKey]} onClose={handleClose}/>
              </Dialog>
            </>
          }
        </>
      }
    </div>
  );
});

export default InvoicesComponent;
