import moment from 'moment/moment';
import React, { FunctionComponent } from 'react';
import { Dialog } from 'ui/Dialog';
import calendarIcon from '../../assets/calendar.svg';
import { Button } from '../../ui/Buttons/Button';

interface RechargingOrderExistDialogProps {
  isActive: boolean;
  onClose: () => void;
  takeNewOrder: () => void;
  orderMonth: Date | null;
}

const RechargingOrderExistDialog: FunctionComponent<RechargingOrderExistDialogProps> = (props) => {
  const { isActive, orderMonth, onClose, takeNewOrder } = props;
  return (
    <Dialog open={isActive} onClose={onClose} position={'center'} >
      <div className={'space-y-4 p-10'}>
        <p className={'font-bold text-xl'}>{`Commande du mois ${moment(orderMonth).format('MMMM YYYY')}`}</p>
        <div className={'flex flex-col justify-center items-center'}>
          <div>
            <img width={50} alt={'Chargement de fichier'} src={calendarIcon}/>
          </div>
          <p className={'font-bold py-2'}>
            {`Vous avez déjà passé une commande pour ${moment(orderMonth).format('MMMM YYYY')}`}
          </p>
          <p>Etes-vous certain de vouloir commander à nouveau pour ce mois?</p>
        </div>
        <div className={'flex justify-center gap-4'}>
          <Button variant="contained" onClick={takeNewOrder}>
            Oui, commander à nouveau pour ce mois
          </Button>
          <Button variant="contained" onClick={onClose}>
            Annuler
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default RechargingOrderExistDialog;