import { FormikHelpers, useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInstances } from 'react-ioc';
import { useNavigate } from 'react-router';
import openeat from '../assets/logo-text-new.svg';
import MailSvg from '../assets/mail';
import resetSuccess from '../assets/reset-pass-success.svg';
import { UserConnectStore } from '../Stores/UserConnect.store';
import { LoginViewStore } from '../Stores/viewStore/LoginView.store';
import { validationEmail } from '../Utils/Login.service';
import { checkIsActivatedUser, requestPasswordChangeForAgencyAdmin } from '../Services/authAPI.service';
import { sanitizeEmail } from '../assets/utils/functions/sanitizeEmail.util';
import { ArrowRightSvg } from '../assets/arrowRightSvg';
import { Button } from '../ui/Buttons/Button';
import { InputField } from '../ui/Input';
import { AuthLayout } from './AuthLayout';
import { sanitizeEmailFromSearchParams } from '../Utils/format.utils';

type LocalStore = [UserConnectStore, LoginViewStore];
type LocalFormValue = {
  email: string
};

const LoginPage = observer(() => {

  const [userConnectStore, loginViewStore]: LocalStore = useInstances<LocalStore>(UserConnectStore, LoginViewStore);
  const navigate = useNavigate();
  const { t } = useTranslation('auth');
  const search = location.search;
  const params: URLSearchParams = new URLSearchParams(search);
  const [email, setEmail] = useState(params.get('email') ? sanitizeEmailFromSearchParams(params.get('email')) : '');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (userConnectStore.isConnected) {
      navigate('/dashboard');
    }
  }, [userConnectStore.isConnected]);

  async function loginEmail({ email }: LocalFormValue, setSubmitting: (isSubmitting: boolean) => void) {
    setSubmitting(true);
    try {
      const sanitizedEmail: string = sanitizeEmail(email);
      loginViewStore.setEmail(sanitizedEmail);
      const { isActivated, isUserAuthorizedOnApp } = await checkIsActivatedUser({
        email: sanitizedEmail, appTarget: 'webemployeur',
      });

      if (isUserAuthorizedOnApp && !isActivated) {
        loginViewStore.openFirstConnexionScreen();
      } else {
        navigate('/login/password');
      }
    } catch (e) {
      navigate('/login/password');
    }

    setSubmitting(false);
  }

  async function handleResetPasswordRequest({ email }: LocalFormValue): Promise<void> {
    try {
      setIsLoading(true);
      await requestPasswordChangeForAgencyAdmin({ email });
      setIsLoading(false);
      loginViewStore.hasChangedPasswordScreen = true;
      loginViewStore.firstConnexionScreen = false;
    } catch (error) {
      setIsLoading(false);
      throw error;
    }
  }

  const formik = useFormik({
    initialValues: {
      email: email,
    },
    validationSchema: validationEmail,
    onSubmit: (
      values: LocalFormValue,
      { setSubmitting }: FormikHelpers<LocalFormValue>,
    ) => loginEmail(values, setSubmitting),
  });

  return (
    <AuthLayout>
      {(loginViewStore.hasChangedPasswordScreen === false && loginViewStore.firstConnexionScreen === false) && (
        <div className={'flex flex-col items-center space-y-8'}>
          <img className="block md:hidden w-[200px]" alt="Openeat" src={openeat}/>
          <p className={'font-bold md:text-3xl text-center'}>{t('subtitle')}</p>

          <form className={'w-full lg:w-2/3 xl:w-1/2 space-y-4'} onSubmit={formik.handleSubmit} noValidate>
            <InputField
              id="email"
              name="email"
              label={t('email')}
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={Boolean(formik.errors.email) && formik.touched.email}
              fullWidth
              required
              placeholder={t('emailPlaceholder')}
              endIcon={<MailSvg/>}
              errorMessage={formik.errors.email}
              autoComplete="email"
            />
            <Button
              loading={formik.isSubmitting}
              disabled={formik.isSubmitting}
              variant={'outlined'}
              position={'right'}
              type={'submit'}
            >
              {t('next')}
              <ArrowRightSvg/>
            </Button>
          </form>
        </div>
      )}
      {loginViewStore.hasChangedPasswordScreen === true && (
        <div className={'flex flex-col justify-center items-center space-y-8 w-full lg:w-2/3 mx-auto'}>
          <img className="block md:hidden w-[200px]" alt="Openeat" src={openeat}/>
          <img className={'w-[125px] mb-4'} alt="Succès du reset de mot de passe" src={resetSuccess}/>
          <p className={'self-start'}>{t('isUserImportedMessage')}</p>
          <p className={'self-start'}>{t('isUserImportedMessage_two')}</p>
        </div>
      )}
      {loginViewStore.firstConnexionScreen === true && (
        <div className={'flex flex-col justify-center items-center space-y-8 w-full lg:w-2/3 mx-auto'}>
          <img className="block md:hidden w-[200px]" alt="Openeat" src={openeat}/>
          <img className={'w-[125px] mb-4'} alt="Succès du reset de mot de passe" src={resetSuccess}/>
          <p>{t('firstConnexion')}</p>
          <p>{t('firstConnexion_tow')}</p>
          <Button
            loading={isLoading}
            disabled={isLoading}
            variant={'outlined'}
            onClick={() => handleResetPasswordRequest({ email: formik.values.email })}
          >
            {t('next')}
            <ArrowRightSvg/>
          </Button>
        </div>
      )}
    </AuthLayout>
  );
});

export default LoginPage;