import React, { FC } from 'react';
import { Button as BaseButton, ButtonProps } from '@mui/material';
import clsx from 'clsx';
import { Spinner } from '../Spinner';

type ButtonVariant = 'contained' | 'outlined' | 'text';
type ButtonSize = 'small' | 'medium' | 'large';
type ButtonPosition = 'right' | 'default';

interface ButtonCompProps extends Omit<ButtonProps, 'variant' | 'size'> {
  loading?: boolean;
  position?: ButtonPosition;
  variant?: ButtonVariant;
  size?: ButtonSize;
  fullWidth?: boolean;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
}

const CLASSES = {
  base: clsx(
    'font-primary',
    'gap-2',
    'normal-case',
    'font-semibold',
    'rounded-br30',
    'transition-all',
    'duration-300',
    'flex',
    'items-center',
    'justify-center',
    'disabled:opacity-50',
    'disabled:cursor-not-allowed',
    'focus:outline-none',
    'focus:ring-0',
    'focus:ring-offset-0',
    'focus:ring-primary/50'
  ),
  variants: {
    contained: clsx(
      'bg-primary',
      'text-white',
      'border-primary',
      'hover:bg-primary/90',
      'active:bg-primary/80'
    ),
    outlined: clsx(
      'border',
      'border-primary',
      'text-primary',
      'bg-white',
      'hover:bg-gray-100',
      'active:bg-gray-200'
    ),
    text: clsx(
      'text-primary',
      'bg-transparent',
      'hover:bg-primary/10',
      'active:bg-primary/20'
    ),
  },
  position: {
    right: 'ml-auto',
    default: '',
  },
  size: {
    small: 'px-4 py-2 text-xs min-h-8',
    medium: 'px-6 py-3 text-sm min-h-11',
    large: 'px-6 py-5 text-sm min-h-14',
  },
  iconWrapper: {
    small: 'w-4 h-4',
    medium: 'w-5 h-5',
    large: 'w-6 h-6',
  },
  loadingWrapper: clsx(
    'absolute',
    'left-1/2',
    '-translate-x-1/2',
    'flex',
    'items-center',
    'justify-center'
  ),
  contentWrapper: (loading?: boolean) =>
    clsx('flex items-center gap-2', loading && 'invisible'),
} as const;

export const Button: FC<ButtonCompProps> = (props) => {
  const {
    loading,
    children,
    size = 'medium',
    variant = 'contained',
    position = 'default',
    className,
    disabled,
    startIcon,
    endIcon,
    fullWidth,
    ...rest
  } = props;

  const isDisabled: boolean = disabled || loading;

  const buttonClasses = clsx(
    CLASSES.base,
    CLASSES.variants[variant],
    CLASSES.position[position],
    CLASSES.size[size],
    fullWidth && 'w-full',
    className
  );

  const iconClasses: string = CLASSES.iconWrapper[size];

  return (
    <BaseButton
      {...rest}
      variant={variant}
      disabled={isDisabled}
      className={buttonClasses}
    >
      {loading && (
        <div className={CLASSES.loadingWrapper}>
          <Spinner
            variant={variant === 'outlined' ? 'black' : 'white'}
            size={'sm'}
          />
        </div>
      )}

      <div className={CLASSES.contentWrapper(loading)}>
        {startIcon && <span className={iconClasses}>{startIcon}</span>}
        {children}
        {endIcon && <span className={iconClasses}>{endIcon}</span>}
      </div>
    </BaseButton>
  );
};