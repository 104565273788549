import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useInstances } from 'react-ioc';
import { useTranslation } from 'react-i18next';
import SummaryCard from './Components/SummaryCard/SummaryCard';
import { AgenciesStore } from '../Stores/Agencies.store';
import { getListOfProfiles } from '../assets/utils/security/agencyAdmins.util';
import { SectionWrapper } from '../ui/layout/SectionWrapper';
import { PageBlock } from 'ui/layout/PageBlock';

type LocalStore = [AgenciesStore];

const SettingsPage: FunctionComponent = observer(() => {
  const { t } = useTranslation('settings');

  const [agenciesStore]: LocalStore = useInstances<LocalStore>(AgenciesStore);
  const [isLoadingProfiles, setIsLoadingProfiles] = useState<boolean>(true);
  const [totalProfiles, setTotalProfiles] = useState<number>(0);

  const { currentAgency, currentAgencyAgencyAdminsStatus } = agenciesStore;
  const fetchTotalProfiles: () => Promise<void> = useCallback(async () => {
    if (currentAgency?.uid) {
      const profiles = await getListOfProfiles(currentAgency.uid);
      setTotalProfiles(profiles.length);
      setIsLoadingProfiles(false);
    }
  }, [currentAgency?.uid]);

  useEffect(() => {
    fetchTotalProfiles();
  }, [fetchTotalProfiles]);

  useEffect(() => {
    if (currentAgency?.uid && currentAgencyAgencyAdminsStatus !== 'SUCCESS') {
      void agenciesStore.refreshAgencyAdminsWithSettings();
    }
  }, [currentAgency?.uid]);

  return (
    <PageBlock>
      <SectionWrapper>
        <h1>{t('title')}</h1>
        <div className={'flex gap-6 mt-5'}>
          <SummaryCard
            isLoading={isLoadingProfiles}
            number={totalProfiles}
            label={t('summaryCardLabel')}
            linkTo={'profiles'}
          />
          <SummaryCard
            isLoading={currentAgencyAgencyAdminsStatus !== 'SUCCESS'}
            number={agenciesStore.currentAgencyAgencyAdmins.length}
            label={t('agencyAdmins')}
            linkTo={'agencyadmins'}
          />
        </div>
      </SectionWrapper>
    </PageBlock>
  );
});

export default SettingsPage;