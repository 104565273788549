import React, { ReactNode } from 'react';
import { Button } from '../../ui/Buttons/Button';
import clsx from 'clsx';
import { DefaultComponentProps } from '@mui/material/OverridableComponent';

interface Props {
  onClick: () => void;
  buttonText: string;
  children: ReactNode;
}

const CLASSES = {
  base: 'w-64 min-h-96 p-4 flex flex-col justify-between bg-background rounded-br10 cursor-pointer',
  animation: 'transition-all duration-200 ease-in-out hover:shadow-xl',
  content: 'flex flex-1 flex-col space-y-4',
}

const BaseCard = (props: Props) => {
  const { children, buttonText, onClick } = props;

  return (
    <div
      className={clsx(CLASSES.base, CLASSES.animation)}>
      <div className={CLASSES.content}>
        {children}
      </div>
      <Button
        variant="outlined"
        size={'large'}
        fullWidth
        onClick={onClick}
      >
        {buttonText}
      </Button>
    </div>
  );
};

export default BaseCard;
