import * as React from 'react';
import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useInstance } from 'react-ioc';
import { StepperRechargingStore } from '../Stores/viewStore/StepperRecharging.store';
import { IStepperComponentProps } from '../Models/Interfaces/IStepperComponent.model';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

const StepperComponent = observer((props: IStepperComponentProps) => {
  const { className, steps, onChange, onComplete } = props;

  const stepperRecharging: StepperRechargingStore = useInstance<StepperRechargingStore>(StepperRechargingStore);
  const current: number = stepperRecharging.currentStepIndex;
  const { t } = useTranslation('reloading');

  useEffect(() => {
    if (onChange) {
      onChange(current);
    }

    if (current >= steps.length && onComplete) {
      onComplete(steps.length);
    }
  }, [current]);

  return (
    <div className={clsx(className, 'relative flex flex-row w-full pb-8 mb-10')}>
      <span className={clsx(
        'flex h-2 flex-1 my-1.5 first:rounded-l-[3.5px] last:rounded-r-[3.5px]',
        'bg-primary'
      )}/>
      {steps.map((step, key) => {
        const completedStep: boolean = key < current;
        const previousStep: boolean = key < current;
        const currentStep: boolean = key === current;

        return (
          <React.Fragment key={key}>
            <div onClick={() => stepperRecharging.returnToStep(step)}>
              <span className={clsx(
                'flex justify-center items-center h-5 w-5 flex-shrink-0 rounded-full mx-2',
                completedStep ? 'bg-primary cursor-pointer' :
                  currentStep ? 'bg-green' : 'bg-grey-light'
              )}>
                <span
                  onClick={() => previousStep}
                  className={clsx(
                    'absolute top-6 w-max text-sm leading-4 text-primary text-center',
                    {
                      'font-semibold': currentStep,
                      'cursor-pointer': previousStep,
                    }
                  )}
                  style={{
                    maxWidth: `calc(100% / ${steps.length + 1} - 10px)`
                  }}>
                  {t(step)}
                </span>
              </span>
            </div>
            <span className={clsx(
              'flex h-2 flex-1 my-1.5',
              previousStep ? 'bg-primary' : 'bg-grey-light'
            )}/>
          </React.Fragment>
        );
      })}
    </div>
  );
});

export default StepperComponent;
