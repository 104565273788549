import Yup from '../../../i18n/validation';
import { BeneficiaryModel } from '@assets/models/beneficiaries/Beneficiary.model';
import React from 'react';
import { observer } from 'mobx-react-lite';
import { SelectGenderModel } from '@assets/models/specials/selectListData.model';
import { MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FunctionFormComponent } from './FunctionFormComponent.model';
import { Select } from '../../../ui/Select';
import { InputField } from '../../../ui/Input';

export const CIVILITY = 'title';
export const FIRST_NAME = 'firstName';
export const LAST_NAME = 'lastName';

const IdentityFormComponent: FunctionFormComponent = observer(({ formik, beneficiary, isEditMode = false, disableModification = false }) => {
  const { t } = useTranslation('beneficiaries');

  const listOfTitleBeneficiary: SelectGenderModel[] = [
    { label: 'M.', value: 'Mr' },
    { label: 'Mme', value: 'Mrs' },
    { label: 'Aucun', value: 'Unknown' },
  ];

  return (
    <div className={'space-y-2'}>
      <h3 className={'font-bold'}>
        Identité
      </h3>

      <div className={'w-1/2 pb-1'}>
        <Select
          fullWidth
          value={formik.values[CIVILITY]}
          onChange={(evt: React.ChangeEvent<HTMLInputElement>) => formik.setFieldValue(CIVILITY, evt.target.value)}
          id={CIVILITY}
          disabled={disableModification}
          label={t('civility')}
          required
        >
          {
            listOfTitleBeneficiary.map((title, index) =>
              <MenuItem key={index} value={title.value}>{t(title.label)}</MenuItem>,
            )
          }
        </Select>
      </div>

      <InputField
        id={LAST_NAME}
        name={LAST_NAME}
        label={t(LAST_NAME)}
        placeholder={t(`${LAST_NAME}Placeholder`)}
        disabled={disableModification}
        formik={formik}
        required
        fullWidth
      />

      <InputField
        id={FIRST_NAME}
        name={FIRST_NAME}
        label={t(FIRST_NAME)}
        placeholder={t(`${FIRST_NAME}Placeholder`)}
        disabled={disableModification}
        formik={formik}
        required
        fullWidth
      />
    </div>
  )
}) as FunctionFormComponent;

IdentityFormComponent.getValidationSchema = () => {
  return Yup.object().shape({
    [FIRST_NAME]: Yup.string().required(),
    [LAST_NAME]: Yup.string().required(),
  });
}

IdentityFormComponent.getInitialValues = (beneficiary?: BeneficiaryModel) => {
  return {
    [CIVILITY]: beneficiary?.title ?? 'Mrs',
    [FIRST_NAME]: beneficiary?.firstName ?? '',
    [LAST_NAME]: beneficiary?.lastName ?? '',
  }
}

export default IdentityFormComponent;