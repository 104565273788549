import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import {
  AgencyAdminProfileDefinition as ProfileDefinition,
} from '../../../assets/models/security/agencyAdmins.model';

import { ReactComponent as ArrowBracketSvg } from '../../../assets/svg/arrowBracket.svg';

type AgencyAdminCapabilitySplit = {
  name: string;
  right: string;
}

function groupCapabilities(params: GridRenderCellParams) {
  const capabilitiesArray: AgencyAdminCapabilitySplit[] = params.value
    .map((capability: string): AgencyAdminCapabilitySplit => {
      const [name, right] = capability.split('.');
      return { name, right };
    });

  return capabilitiesArray.reduce((acc, capability: AgencyAdminCapabilitySplit) => {
    if (!acc[capability.name] || capability.right === 'write') {
      acc[capability.name] = capability;
    }
    return acc;
  }, {});
}

export const ProfileColumns: GridColDef[] = [
  {
    field: 'identifier',
    flex: 1,
    renderHeader: () => {
      const { t } = useTranslation('settings');
      return (
        <p className={'font-bold text-sm'}>{t('identifier')}</p>
      );
    },
  },
  {
    field: 'name',
    flex: 1,
    renderHeader: () => {
      const { t } = useTranslation('settings');
      return (
        <p className={'font-bold text-sm'}>{t('name')}</p>
      );
    },
  },
  {
    field: 'rights',
    flex: 1,
    renderHeader: () => {
      const { t } = useTranslation('settings');
      return (
        <p className={'font-bold text-sm'}>{t('rights')}</p>
      );
    },
    renderCell: (params: GridRenderCellParams) => {
      const { t } = useTranslation('settings');
      const capabilities = groupCapabilities(params);

      return (
        <>
          {Object.values(capabilities).map((capability: AgencyAdminCapabilitySplit, index) => (
            <p
              key={index}
              className={'inline bg-green text-primary px-2 py-1 rounded-lg mr-2 justify-center align-middle items-center'}
            >
              {t(capability.name)}
            </p>
          ))}
        </>
      );
    },
  },
  {
    field: 'arrow',
    headerName: '',
    display: 'flex',
    renderCell: () => {
      return (
        <ArrowBracketSvg width={14} className={'self-center m-auto'}/>
      );
    },
  },
];

export const ProfileRows = (profiles: ProfileDefinition[]) => {
  return profiles.map(({ uid, code, name, capabilities }: ProfileDefinition) => {
    return {
      id: uid,
      identifier: code,
      name: name,
      rights: capabilities,
    };
  });
};
