import React, { FC } from 'react';
import { FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import { FormControl } from '@mui/material';
import clsx from 'clsx';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { ReactComponent as CalendarSvg } from '../assets/svg/calendar.svg';

interface Props {
  id: string;
  name: string;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  errorMessage?: string;
  error?: boolean;
  formik?: FormikProps<any>;
  className?: string;
  formClasses?: string;
}

const styles = {
  base: {
    input: 'text-sm rounded-br30 h-16 bg-background disabled:opacity-50 text-primary border border-solid border-background hover:border-muted transition-all duration-300 ease-in-out',
    label: 'absolute top-2 left-0 z-50 px-6 w-full flex justify-between text-primary/50 text-sm',
    error: 'pt-1 text-sm transition-opacity duration-300 ease-in-out text-status-error',
  },
  states: {
    error: 'border-status-error',
    disabled: 'border-status-disabled bg-white hover:border-status-disabled',
  },
  mui: {
    outline: {
      '& .MuiInputBase-root.MuiOutlinedInput-root': {
        height: '100%',
        borderRadius: '30px',
        position: 'relative',
        alignItems: 'self-end',
        border: 'none',

        '& .MuiInputAdornment-root': {
          paddingBottom: '0',
          marginRight: '0.5rem',
        },

        '& .MuiOutlinedInput-input': {
          padding: '0 1.5rem',
          paddingBottom: '0.3rem',
        },
        '& .MuiOutlinedInput-notchedOutline, &:hover .MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline, &.Mui-error .MuiOutlinedInput-notchedOutline, &.Mui-disabled .MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
      },
    },
  },
};

export const DatePicker: FC<Props> = (props) => {
  const {
    id,
    name,
    label,
    required,
    disabled,
    fullWidth,
    errorMessage,
    error,
    formik,
    className,
  } = props;
  const { t } = useTranslation('common');

  const formikValue = formik && id ? formik.values[id] : undefined;
  const formikError = formik && id ? formik.touched[id] && formik.errors[id] as string : undefined;
  const hasError = error || Boolean(formikError);
  const finalErrorMessage = errorMessage || formikError;

  const dateValue = formikValue ? dayjs(formikValue) : null;

  const handleChange = (newValue: any) => {
    if (formik && id) {
      const isoDate = newValue ? newValue.format('YYYY-MM-DD') : '';
      formik.setFieldValue(id, isoDate);
      formik.setFieldTouched(id, true, false);
      formik.validateField(id);
    }
  };

  const handleBlur = () => {
    if (formik && id) {
      formik.setFieldTouched(id, true);
      if (required && !formikValue) {
        formik.setFieldError(id, t('required'));
      }
    }
  };

  return (
    <FormControl fullWidth={fullWidth} required={required} className="h-16">
      <div>
        {label && (
          <div className={styles.base.label}>
            <span id={id + '-label'}>{label}</span>
            {required && <span>{t('required')}</span>}
          </div>
        )}
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <MuiDatePicker
            value={dateValue}
            onChange={handleChange}
            onClose={handleBlur}
            disabled={disabled}
            format="DD/MM/YYYY"
            slots={{
              openPickerIcon: () => <CalendarSvg className="w-4 h-4 text-primary/50"/>
            }}
            slotProps={{
              textField: {
                id,
                name,
                error: hasError,
                fullWidth,
                onBlur: handleBlur,
                className: clsx(
                  styles.base.input,
                  disabled && styles.states.disabled,
                  hasError && styles.states.error,
                  className
                ),
                sx: styles.mui.outline
              },
            }}
          />
        </LocalizationProvider>
      </div>
      <p className={clsx(styles.base.error, hasError ? 'opacity-100' : 'opacity-0')}>
        {finalErrorMessage}
      </p>
    </FormControl>
  );
};