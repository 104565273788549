import { AppBar, Tabs, Tab, TabsProps } from '@mui/material';
import React, { FC, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

export interface TabDefinition<T = any, P = any> {
  label: string;
  path?: string;
  Component?: FC<P>;
  isVisible: (param?: T) => boolean;
  props?: P;
}

interface Props extends TabsProps {
  tabs: TabDefinition[];
  value?: number;
  fullwidth?: boolean;
}

export const TabBar: FC<Props> = (props) => {
  const { tabs, value, fullwidth, ...rest } = props;

  const getActiveIndex = useCallback(() => {
    const index = tabs.findIndex((tab, idx) => tab.path ? tab.path === location.pathname : idx === value);
    return index !== -1 ? index : 0;
  }, [tabs, location.pathname, value]);

  const activeIndex: number = useMemo(() => getActiveIndex(), [getActiveIndex]);
  const activeTab: TabDefinition = useMemo(() => tabs[activeIndex] ?? null, [tabs, activeIndex]);

  return (
    <>
      <AppBar position="static" color="default" className="shadow-none pb-2 bg-white">
        <Tabs
          {...rest}
          value={activeIndex}
          className="bg-white !border border-solid !border-x-0 !border-t-0 border-b border-primary/25"
          TabIndicatorProps={{
            className: 'bg-primary',
          }}
        >
          {tabs.map((tab, index) => (
            <Tab
              component={tab.path ? Link : 'button'}
              to={tab.path ?? undefined}
              className={clsx(
                'text-primary text-sm py-4 normal-case',
                fullwidth && 'flex-1',
                activeIndex === index ? 'font-bold' : '',
              )}
              label={tab.label}
              key={index}
            />
          ))}
        </Tabs>
      </AppBar>

      {activeTab?.Component && (<activeTab.Component {...activeTab.props} />)}
    </>
  );
};
