import React from 'react';
import clsx from 'clsx';

interface Props {
  className?: string;
  isActive: boolean;
  label: string;
  size?: 'sm' | 'base' | 'xl';
}

export const StatusLed = (props: Props) => {
  const { label, className, isActive, size = 'base' } = props;

  return (
    <div className={clsx('flex space-x-2 item-center', className)}>
      <div className={clsx(
        'w-3.5 h-3.5 rounded-full self-center',
        isActive ? 'bg-status-success' : 'bg-status-failed',
      )}/>
      <p className={`text-${size}`}>{label}</p>
    </div>
  );
};
