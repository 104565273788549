import React, { useEffect } from 'react';
import { useInstances } from 'react-ioc';
import { AgenciesStore } from '../../../Stores/Agencies.store';
import { observer } from 'mobx-react-lite';
import { Spinner } from 'ui/Spinner';
import { DataSpreadsheet } from '../../../ui/DataSpreadsheet';
import { AgencyAdminColumns, AgencyAdminRows } from './AgencyAdminDataSpreadSheet';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { GridCellParams } from '@mui/x-data-grid';

type LocalStore = [AgenciesStore];

const AgencyAdminsList = observer(() => {
  const navigate: NavigateFunction = useNavigate();

  const [agenciesStore]: LocalStore = useInstances<LocalStore>(AgenciesStore);
  const {
    currentAgency,
    currentAgencyAgencyAdmins,
    currentAgencyAgencyAdminsStatus,
    currentAgencyManagementUnits,
  } = agenciesStore;

  useEffect(() => {
    if (currentAgency?.uid && currentAgencyAgencyAdminsStatus !== 'SUCCESS') {
      void agenciesStore.refreshAgencyAdminsWithSettings();
    }
  }, [currentAgency?.uid]);

  return (
    ['LOADING', 'PENDING'].includes(currentAgencyAgencyAdminsStatus) ? <Spinner size={'lg'}/> :
      <DataSpreadsheet
        columns={AgencyAdminColumns}
        rows={AgencyAdminRows(currentAgencyAgencyAdmins, currentAgencyManagementUnits)}
        onCellClick={({ field, row }: GridCellParams) => field === 'arrow' && row.onclick(navigate)}
        disableRowSelectionOnClick
      />
  ); 
});

export default AgencyAdminsList;