import React from 'react';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import capitalizeFirstLetter from '../Function/CapitalizeFirstLetter';
import clsx from 'clsx';

export const columnsBeneficiaries: GridColDef[] = [
  {
    field: 'registrationNumber',
    headerName: 'Matricule',
    type: 'number',
    headerAlign: 'left',
    align: 'left',
    flex: 1,
  },
  {
    field: 'title',
    type: 'string',
    headerName: 'Titre',
    flex: 1,
    renderHeader: function titleHeader() {
      return (
        <div className={'MuiDataGrid-colCellTitle'} style={{ width: '100%' }}>Titre</div>
      );
    },
    renderCell: function titleCell(params: GridCellParams) {
      const { t } = useTranslation('beneficiaries');
      return (
        <div style={{ width: '100%' }}>{t(params.value.toString())} </div>
      );
    },
  },
  {
    field: 'lastName',
    headerName: 'Nom',
    flex: 2,
    renderCell: function titleCell(params: GridCellParams) {
      return (
        <div style={{ width: '100%' }}>{params.value.toString().toUpperCase()} </div>
      );
    },
  },
  {
    field: 'firstName',
    headerName: 'Prénom',
    flex: 2,
    renderCell: function titleCell(params: GridCellParams) {
      return (
        <div style={{ width: '100%' }}>{capitalizeFirstLetter(params.value.toString())} </div>
      );
    },
  },
  {
    field: 'email',
    headerName: 'Adresse Email',
    flex: 2,
    renderCell: function titleCell(params: GridCellParams) {
      return (
        <div style={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
             title={params.value.toString()}>
          {params.value ? params.value.toString() : ''}
        </div>
      );
    },
  },
  {
    field: 'isActive',
    headerName: 'Actif',
    flex: 1,
    sortComparator: (value: boolean) => value ? 1 : -1,
    renderCell: function isActiveCell(params: GridCellParams) {
      return (
        <div className={'h-full flex items-center'}>
          <div className={clsx('w-2 h-2 rounded-full', params.value ? 'bg-status-success' : 'bg-status-failed')}/>
          <p className={'pl-2 font-semibold'}>{params.value ? 'Actif' : 'Inactif'}</p>
        </div>
      );
    },
  },
];
