import React, { FunctionComponent, useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { HydratedVoucherModel } from '../assets/models/discreteVouchers/DiscreteVoucher.model';
import { FRENCH_MONTHS } from '../assets/utils/dates/dateTime.util';
import { Timestamp } from 'firebase/firestore';
import { downloadAsCSV } from '../Utils/CsvDownload.utils';
import uploadSVG from '../assets/upload-2.svg';
import { convertCentToEuroString } from '../assets/utils/functions/convertCentToEuro.util';
import { onCall } from '../Services/firebase.service';
import { errorStore } from '../Stores/Error.store';
import { AgenciesStore } from '../Stores/Agencies.store';
import { useInstances } from 'react-ioc';
import { Button } from '../ui/Buttons/Button';
import { SectionWrapper } from '../ui/layout/SectionWrapper';
import { Select } from '../ui/Select';
import { MenuItem } from '@mui/material';
import { PageBlock } from '../ui/layout/PageBlock';

type SelectedPeriod = {
  uid: string;
  name: string;
  humanMonth: number; // 1 to 12
  year: number
}

//@todo replace svg

const VouchersComponent: FunctionComponent = (observer(() => {
    const [agenciesStore] = useInstances(AgenciesStore);
    const [selectedPeriodUid, setSelectedPeriodUid] = useState<string>('0');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { currentAgency } = agenciesStore;

    // current month, current month -1, ... ,current month - 5
    const periods: SelectedPeriod[] = [...Array(6).keys()].map(index => {
      const newDate = new Date();
      newDate.setMonth(newDate.getMonth() - index);
      const month = newDate.getMonth();
      const year = newDate.getFullYear();
      return { uid: String(index), name: `${FRENCH_MONTHS[month]} ${year}`, humanMonth: month + 1, year };
    });

    const convertTimestampToLocaleDateString = (value: Timestamp | number): null | string => {
      if (!value) {
        return null;
      }
      const d = typeof value === 'number' ? new Date(value) : value.toDate();
      return d.toLocaleDateString('fr-FR');
    };

    const transformVoucherToCsvObject = (voucher: HydratedVoucherModel) => {
      const {
        beneficiary, business, administration, ministry, bop, managementUnit, pimAmountTTC, pimAmountHT, subAmountTTC,
        subAmountHT, creationDate, transactionAmount, agencyParticipationAmount, transactionDate,
        beneficiaryRemainderAmount,
      } = voucher;

      const {
        email, firstName, lastName, firstRightDate, deactivationDate, dailyVoucherUsageLimit,
        monthlyVoucherUsageLimit,
        registrationNumber,
      } = beneficiary;

      const { postalCode, city, companyName, denomination, siret, businessRegisterNumber } = business;

      return {
        'Email de l\'agent': email,
        'Prénom de l\'agent': firstName,
        'Nom de l\'agent': lastName,
        'Date d\'ouverture des droits': convertTimestampToLocaleDateString(firstRightDate),
        'Date de sortie du dispositif': convertTimestampToLocaleDateString(deactivationDate),
        'Maximum journalier': dailyVoucherUsageLimit ?? 0,
        'Maximum mensuel': monthlyVoucherUsageLimit ?? 0,
        'Matricule': registrationNumber,
        'Code Administration': administration?.code,
        'Administration': administration?.name,
        'Code Bop': bop?.code,
        'Bop': bop?.name,
        'Code Ministère': ministry?.code,
        'Ministère': ministry?.name,
        'Code Unité de gestion': managementUnit?.code,
        'Unité de gestion': managementUnit?.name,
        'Numéro d\'enregistrement du commerçant': businessRegisterNumber,
        'Code postal du commerçant': postalCode,
        'Ville du commerçant': city,
        'Nom du commerçe': companyName,
        'Dénomination': denomination,
        'Siret': siret,
        'Date de création de la transaction': convertTimestampToLocaleDateString(creationDate),
        'Montant repas': convertCentToEuroString(transactionAmount, false),
        'PIM (HT)': convertCentToEuroString(pimAmountHT, false),
        'PIM (TTC)': convertCentToEuroString(pimAmountTTC, false),
        'SUB (HT)': convertCentToEuroString(subAmountHT, false),
        'SUB (TTC)': convertCentToEuroString(subAmountTTC, false),
        'Reste à charge': convertCentToEuroString(beneficiaryRemainderAmount, false),
        'Total participation': convertCentToEuroString(agencyParticipationAmount, false),
        'Date de la transaction': convertTimestampToLocaleDateString(transactionDate),
      };
    };

    const handleExport = useCallback(async () => {
      try {
        if (!!currentAgency?.uid && !!selectedPeriodUid) {
          setIsLoading(true);
          const { humanMonth, year }: SelectedPeriod = periods[selectedPeriodUid];

          const hydratedVouchers: HydratedVoucherModel[] = await onCall('DISCRETE_VOUCHERS-getAgencyVouchers_onCall', {
            agencyUid: currentAgency?.uid, period: { month: humanMonth, year },
          });

          if (hydratedVouchers.length === 0) {
            throw new Error('Aucune transaction a exporter sur cette période...');
          }

          downloadAsCSV(hydratedVouchers.map(transformVoucherToCsvObject), `transactions_${FRENCH_MONTHS[humanMonth - 1].toLowerCase()}_${year}`);
        }
      } catch (error) {
        errorStore.catchError(error);
      } finally {
        setIsLoading(false);
      }

    }, [selectedPeriodUid, currentAgency?.uid]);

    return (
      <PageBlock>
        <SectionWrapper className={'space-y-2'}>
          <h1>Transactions</h1>

          <div className={'w-full space-y-6 flex flex-col justify-between'}>
            <p className={'mb-4 font-bold'}>
              Sélectionner la période de facturation à exporter
            </p>

            <div className={'flex items-end justify-start gap-4'}>
              <Select
                value={selectedPeriodUid}
                onChange={(evt) => setSelectedPeriodUid(evt.target.value as string)}
                label={'Période de facturation'}
              >
                {periods.map((period) => (
                  <MenuItem key={period.name} value={period.uid}>
                    {period.name}
                  </MenuItem>
                ))}
              </Select>

              <Button
                size={'large'}
                startIcon={<img src={uploadSVG} alt={''} width={24} height={24}/>}
                onClick={handleExport}
                disabled={isLoading}
                >
                Exporter
              </Button>
            </div>
          </div>
        </SectionWrapper>
      </PageBlock>
    );
  }))
;

export default VouchersComponent;
