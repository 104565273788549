const auth = {
  infoPanel: {
    title: ' L\'app titre restaurant de demain',
    subtitle: 'Sans carte, sans papier, sans prélèvement sur salaire.',
  },
  email: 'Adresse email',
  emailPlaceholder: 'jean.dupond@mail.com',
  password: 'Mot de passe',
  passwordPlaceholder: 'Renseignez votre mot de passe',
  forgotPassword: 'Je l\'ai oublié',
  connection: 'Me connecter',
  unknownLoginError: '😬 Impossible de se connecter, une erreur inconnue.',
  credentialsError: '😬 Impossible de se connecter, votre email ou votre mot de passe est invalide.',
  deactivatedAccountError: '😬 Impossible de se connecter, votre compte a été désactivé.',
  beneficiariesPartOne_one: 'Collaborateur',
  beneficiariesPartOne_other: 'Collaborateurs',
  beneficiariesPartTwo_one: '{{count}} n’a pas encore activé son compte, ',
  beneficiariesPartTwo_other: '{{count}} n’ont pas encore activés leurs comptes, ',
  beneficiariesPartThree_one: 'le relancer',
  beneficiariesPartThree_other: 'les relancer',
  dailyAmount: 'C\'est le montant alloué au repas par jour et par collaborateur.',
  contribution: 'Correspond aux {{companyQuota}} % de participation de l\'entreprise par jour par collaborateur.',
  hello: '👋 Bienvenue !',
  hello2: ' Comment ça va aujourd\'hui ?',
  cookiesTitle: '🍪 Nous n’utilisons pas de cookies',
  cookiesText1: 'Vous pouvez naviguer en toute tranquillité, ce site n’utilise pas de cookies.',
  cookiesValidation: 'OK, c\'est noté',
  firstname: 'Prénom',
  title_one: '{{count}} collaborateur n’a pas encore activé son compte',
  title_other: '{{count}} collaborateurs n’ont pas encore activé leur compte',

  next: 'Suivant',
  login: 'Me connecter',
  cancel: 'Annuler',
  resetPassword: 'Réinitialiser mon mot de passe',
  subtitle: 'Accéder à votre espace client',
  subtitleResetPassword: 'Vous avez oublié votre mot de passe ?',
  subtitleResetPassword_two: 'Nous allons vous envoyer un lien de réinitialisation.',
  subTitleResetPasswordSuccess: '👍 Vérifiez votre boite mail, nous venons de vous envoyer un lien de réinitialisation.',

  isUserImportedMessage: '🧐 Nous venons de vous envoyer un email de réinitialisation de mot passe, car notre niveau de sécurité a été augmenté.',
  isUserImportedMessage_two: 'Rendez-vous dans votre boîte pour cliquer sur le lien.',
  firstConnexion: '👋 C’est votre première connexion, nous venons de vous envoyer un email pour définir votre mot de mot passe, et accéder à votre espace.',
  firstConnexion_tow: 'Rendez-vous dans votre boîte pour cliquer sur le lien.\n',
  firstConnexion_three: 'Je veux recevoir un nouveau lien.',
};

export default auth;