import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { BeneficiariesCountResponse } from '../../../assets/responses/beneficiaries/BeneficiariesCount.response';
import { AgencyAdminStore } from '../../../Stores/AgencyAdmin.store';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { useInstances } from 'react-ioc';
import { AgenciesStore } from '../../../Stores/Agencies.store';
import { BeneficiariesStore } from '../../../Stores/Beneficiaries.store';
import { getParticipationPercent } from '../../../Utils/AgencyContractValues.service';
import {
  SendMailsToUnboardedBeneficiariesResponse,
} from '../../../assets/responses/emails/SendMailsToUnboardedBeneficiaries.response';
import { AgencyModel } from '../../../assets/models/agencies/Agency.model';
import useClassificationsParticipation, { ContractPart } from '../../../Hooks/useClassificationsParticipation';
import { Spinner } from '../../../ui/Spinner';
import { SectionWrapper } from 'ui/layout/SectionWrapper';
import { RecapBlock } from './RecapBlock';

import { ReactComponent as BeneficiariesSvg } from '../../../assets/svg/dashboardRecap/beneficiaries.svg';
import { ReactComponent as PlateSvg } from '../../../assets/svg/dashboardRecap/plate.svg';
import { ReactComponent as PizzaGraphSvg } from '../../../assets/svg/dashboardRecap/pizza-graph.svg';
import { capitalizeFirstLetter } from '../../../Utils/string.utils';
import { Toaster } from '../../../ui/Toaster';

type LocalStore = [AgenciesStore, AgencyAdminStore, BeneficiariesStore];

const DashboardRecap: FunctionComponent = observer(() => {
  const [agenciesStore, agencyAdminStore, beneficiariesStore]: LocalStore = useInstances<LocalStore>(AgenciesStore, AgencyAdminStore, BeneficiariesStore);
  const [activeBeneficiariesCount, setActiveBeneficiariesCount] = useState<number>(0);
  const [unOnboardedBeneficiariesCount, setUnOnboardedBeneficiariesCount] = useState<number>(0);
  const [isFetchingBeneficiariesCount, setIsFetchingBeneficiariesCount] = useState<boolean>(true);
  const [isFetchingSendEmail, setIsFetchingSendEmails] = useState<boolean>(false);
  const [reportMessage, setReportMessage] = useState<string>('');
  const { t } = useTranslation('dashboard');
  const {
    contractAgencyPart, contractFacialValue,
  }: ContractPart = useClassificationsParticipation(agenciesStore.currentAgencyBeneficiaryClassification);

  const contractPartReady = !!agenciesStore.currentAgencyBeneficiaryClassification;

  const participationPercent = contractPartReady ? getParticipationPercent(contractAgencyPart, contractFacialValue).toFixed(2) : '';

  const currencyFormat: Intl.NumberFormat = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' });

  const successMessage: string = 'Un mail de relance a bien été envoyé à vos collaborateurs concernés';
  const errorMessage: string = 'Nous avons rencontré un problème et nous n’avons pas pu envoyer le mail de relance à vos collaborateurs concernés';

  const { dailyAmountText, contributionText } = useMemo(() => {
    if (!agenciesStore.currentAgency) {
      return { dailyAmountText: '', contributionText: '' };
    }

    const {
      contractType, useBeneficiaryClassification,
    }: Pick<AgencyModel, 'contractType' | 'useBeneficiaryClassification'> = agenciesStore.currentAgency;

    switch (contractType) {
      case 'PUBLIC_MEAL_SUBSIDY':
        return {
          dailyAmountText: t('dailyAmountSubsidy'),
          contributionText: t('contributionSubsidy'),
        };
      default:
        if (useBeneficiaryClassification) {
          return {
            dailyAmountText: t('dailyAmountMultiClass'),
            contributionText: t('contributionMultiClass', { companyQuota: participationPercent }),
          };
        }
        return {
          dailyAmountText: t('dailyAmount'),
          contributionText: t('contribution', { companyQuota: participationPercent }),
        };
    }

  }, [agenciesStore.currentAgency, participationPercent]);

  async function getBeneficiariesCounter(): Promise<void> {
    setIsFetchingBeneficiariesCount(true);

    const beneficiariesCount: BeneficiariesCountResponse = await beneficiariesStore.fetchBeneficiariesCountByAgencyId(agenciesStore.currentAgency.uid);

    setActiveBeneficiariesCount(beneficiariesCount.activeBeneficiariesCount);
    setUnOnboardedBeneficiariesCount(beneficiariesCount.unOnboardedBeneficiariesCount);
    setIsFetchingBeneficiariesCount(false);
  }

  useEffect(() => {
    setReportMessage('');
    if (agenciesStore.currentAgency) {
      (async () => await getBeneficiariesCounter())();
    }
    return setReportMessage('');
  }, [agenciesStore.currentAgency]);

  const sendEmails = async () => {
    setIsFetchingSendEmails(true);
    try {
      const report: SendMailsToUnboardedBeneficiariesResponse = await beneficiariesStore.sendBatchedMailsToUnOnboardedBeneficiaries({ agencyId: agenciesStore.currentAgency.uid });

      if (report.mailsFailed.length) {
        setReportMessage(errorMessage);
      } else if (report.mailsSent.length) {
        setReportMessage(successMessage);
      }
    } catch (e) {
      setReportMessage(errorMessage);
    } finally {
      setIsFetchingSendEmails(false);
    }
  };

  const svgContainerClass = 'inline-flex justify-center items-center bg-link rounded-full h-14 w-14 p-3';

  return (
    <>
      <SectionWrapper className={'flex py-10'}>
        <RecapBlock className={'text-3xl font-bold text-nowrap flex-col pr-32 gap-0'}>
          <div>
            <p>Hello</p>
            <p>{capitalizeFirstLetter(agencyAdminStore.agencyAdminSelected?.firstName)} !</p>
          </div>
        </RecapBlock>

        <RecapBlock>
          {isFetchingBeneficiariesCount
            ? <Spinner/>
            : <>
              <div className={svgContainerClass}>
                <BeneficiariesSvg stroke={'#FFF'} width={40} height={40}/>
              </div>
              <div className={'flex flex-col'}>
                <p className={'text-3xl font-bold leading-8'}>
                  {!isFetchingBeneficiariesCount && activeBeneficiariesCount}
                </p>
                <div>
                  {t('beneficiariesPartOne', { count: activeBeneficiariesCount })}
                  {unOnboardedBeneficiariesCount !== 0 && <>
                    , 🔥 {t('beneficiariesPartTwo', { count: unOnboardedBeneficiariesCount })}&nbsp;
                    {isFetchingSendEmail
                      ? <Spinner/>
                      : <>  <span onClick={() => sendEmails()} className={''}>
                    {t('beneficiariesPartThree', { count: unOnboardedBeneficiariesCount })}
                  </span>&nbsp;?</>}
                  </>
                  }
                </div>
              </div>
            </>
          }
        </RecapBlock>

        <RecapBlock>
          {!contractPartReady
            ? <Spinner/>
            : <>
              <div className={svgContainerClass}>
                <PlateSvg stroke={'#FFF'} width={40} height={40}/>
              </div>
              <div className={'flex flex-col'}>
                <p className={'text-3xl font-bold leading-8'}>
                  {contractFacialValue
                    ? currencyFormat.format(contractFacialValue)
                    : '-'
                  }
                </p>
                <div>{dailyAmountText}</div>
              </div>
            </>
          }
        </RecapBlock>

        <RecapBlock className={'border-none'}>
          {!contractPartReady
            ? <Spinner/>
            : <>
              <div className={svgContainerClass}>
                <PizzaGraphSvg fill={'#FFF'} width={40} height={40}/>
              </div>
              <div>
                <p className={'text-3xl font-bold leading-8'}>
                  {contractAgencyPart
                    ? currencyFormat.format(contractAgencyPart)
                    : '-'
                  }
                </p>
                <div>{contributionText}</div>
              </div>
            </>
          }
        </RecapBlock>
      </SectionWrapper>
      <Toaster variant={'error'} open={Boolean(reportMessage)}>
        <>{reportMessage}</>
      </Toaster>
    </>
  );
});

export default DashboardRecap;
