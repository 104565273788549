import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { Skeleton } from '@mui/material';

import { ReactComponent as ArrowSvg } from '../../../assets/svg/arrow.svg';
import clsx from 'clsx';

interface SummaryCardProps {
  isLoading?: boolean;
  number: number;
  label: string;
  linkTo: string;
}

const CLASSES = {
  base: 'group no-underline rounded-br10 overflow-hidden',
  animation: 'hover:shadow-xl transition-all duration-200 ease-in-out',
  card: 'flex flex-col justify-between w-72 min-h-44 bg-background p-3.5',
  label: 'text-sm text-primary font-bold self-center',
  arrowContainer: 'flex w-10 h-10 text-primary bg-green group-hover:bg-green/50 rounded-full justify-center',
};

const SummaryCard: FunctionComponent<SummaryCardProps> = ({ number, label, linkTo, isLoading = false }) => {
  return (
    <Link to={linkTo}
          className={clsx(CLASSES.base, CLASSES.animation)}>
      <div className={CLASSES.card}>
        <div>
          {isLoading
            ? <Skeleton animation="wave" variant="text" width={'64px'}/>
            : <p className={'text-4xl text-primary'}>{number}</p>
          }
        </div>
        <div className={'flex justify-between'}>
          <div className={CLASSES.label}>{label}</div>
          <div
            className={CLASSES.arrowContainer}
          >
            <ArrowSvg width={14} className={'-rotate-90 self-center'}/>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default SummaryCard;